import { Schema, lazy } from "yup";
import { PersistedTextBlock, TextBlockPayload, TextBlockSchema } from "./TextBlock";
import { ImageBlockPayload, ImageBlockSchema, PersistedImageBlock } from "./ImageBlock";
import { PersistedVideoBlock, VideoBlockPayload, VideoBlockSchema } from "./VideoBlock";
import { EmailBlockPayload, EmailBlockSchema, PersistedEmailBlock } from "./EmailBlock";
import { PersistedPhoneBlock, PhoneBlockPayload, PhoneBlockSchema } from "./PhoneBlock";
import { LinkBlockPayload, LinkBlockSchema, PersistedLinkBlock } from "./LinkBlock";
import { AppointmentBlockPayload, AppointmentBlockSchema, PersistedAppointmentBlock } from "./AppointmentBlock";
import { PageBlockPayload, PageBlockSchema, PersistedPageBlock } from "./PageBlock";
import {
  AppointmentLinkBlockPayload,
  AppointmentLinkBlockSchema,
  PersistedAppointmentLinkBlock,
} from "./AppointmentLinkBlock";
import { PersistedSocialLinksBlock, SocialLinksBlockPayload, SocialLinksBlockSchema } from "./SocialLinksBlock";
import { FormLinkBlockPayload, FormLinkBlockSchema, PersistedFormLinkBlock } from "./FormLinkBlock";

export type BlockKind =
  | "AppointmentBlock"
  | "AppointmentLinkBlock"
  | "EmailBlock"
  | "FormLinkBlock"
  | "ImageBlock"
  | "LinkBlock"
  | "PageBlock"
  | "PhoneBlock"
  | "SocialLinksBlock"
  | "TextBlock"
  | "VideoBlock"
  | "VideoLinkBlock";

export const BlockKindValues: BlockKind[] = [
  "AppointmentBlock",
  "AppointmentLinkBlock",
  "EmailBlock",
  "FormLinkBlock",
  "ImageBlock",
  "LinkBlock",
  "PageBlock",
  "PhoneBlock",
  "SocialLinksBlock",
  "TextBlock",
  "VideoBlock",
  "VideoLinkBlock",
];

export type InlineEditableBlockKinds = "TextBlock" | "ImageBlock" | "VideoBlock" | "VideoLinkBlock";
export const InlineEditableBlockKindValues: BlockKind[] = ["TextBlock", "ImageBlock", "VideoLinkBlock", "VideoBlock"];

export type EditableBlockKinds =
  | "AppointmentLinkBlock"
  | "EmailBlock"
  | "FormLinkBlock"
  | "LinkBlock"
  | "PhoneBlock"
  | "SocialLinksBlock";

export type BlockPayload =
  | AppointmentBlockPayload
  | AppointmentLinkBlockPayload
  | EmailBlockPayload
  | FormLinkBlockPayload
  | ImageBlockPayload
  | LinkBlockPayload
  | PageBlockPayload
  | PhoneBlockPayload
  | SocialLinksBlockPayload
  | TextBlockPayload
  | VideoBlockPayload;

export type PersistedInlineEditableBlock = PersistedTextBlock | PersistedImageBlock | PersistedVideoBlock;

export type PersistedBlock =
  | PersistedInlineEditableBlock
  | PersistedAppointmentBlock
  | PersistedAppointmentLinkBlock
  | PersistedEmailBlock
  | PersistedFormLinkBlock
  | PersistedLinkBlock
  | PersistedPageBlock
  | PersistedPhoneBlock
  | PersistedSocialLinksBlock
  | PersistedVideoBlock;

const blockSchemas: Record<BlockKind, Schema> = {
  AppointmentBlock: AppointmentBlockSchema,
  AppointmentLinkBlock: AppointmentLinkBlockSchema,
  EmailBlock: EmailBlockSchema,
  FormLinkBlock: FormLinkBlockSchema,
  ImageBlock: ImageBlockSchema,
  LinkBlock: LinkBlockSchema,
  PageBlock: PageBlockSchema,
  PhoneBlock: PhoneBlockSchema,
  SocialLinksBlock: SocialLinksBlockSchema,
  TextBlock: TextBlockSchema,
  VideoBlock: VideoBlockSchema,
  VideoLinkBlock: VideoBlockSchema,
};

export const LazyBlockSchema = lazy((value) => blockSchemas[value.kind as BlockKind]);

export async function validateBlock(block: BlockPayload): Promise<BlockPayload> {
  return blockSchemas[block.kind].validate(block);
}

export function emptyBlock(kind: BlockKind): BlockPayload {
  switch (kind) {
    case "TextBlock":
      return { text: "", kind };
    case "ImageBlock":
      return { alt: "", image: "", kind };
    case "VideoBlock":
    case "VideoLinkBlock":
      return { video: "", kind: "VideoBlock" };
    case "EmailBlock":
      return { email: "", label: "", kind };
    case "FormLinkBlock":
      return { form: "", kind };
    case "PhoneBlock":
      return { number: "", label: "", kind };
    case "LinkBlock":
      return { url: "", label: "", kind };
    case "AppointmentBlock":
      return { appointment: "", kind };
    case "AppointmentLinkBlock":
      return { appointment: "", kind };
    case "PageBlock":
      return { page: "", kind };
    case "SocialLinksBlock":
      return { links: [], kind };
  }
}
