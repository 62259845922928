import { useParams } from "react-router-dom";
import {
  Animation,
  SmallTextMain,
  SubheaderMain,
  TextMain,
  flexStyles,
  iconStyles,
  marginStyles,
  paddingStyles,
  textStyles,
} from "ui";
import classNames from "classnames";
import { Button, Overlay } from "../ui";
import { useBackLocation } from "../../hooks";

type Sources = "calendars" | "sms" | "branding" | "contacts";
type DicoveredContent = {
  prompt?: string;
  header: string;
  content: string;
};

const Contents: Record<Sources, DicoveredContent> = {
  calendars: {
    header: "Adding Multiple Calendars",
    content:
      "Adding multiple calendars is one of our awesome features on the LiteSite Business plan. Do you wanna hear more?",
  },
  sms: {
    header: "SMS Automations",
    content:
      "Don't settle for just email communication - upgrade to LiteSite's Business plan and automate SMS messages to your customers!",
  },
  branding: {
    header: "Remove LiteSite branding",

    content: "Say goodbye to LiteSite branding and make your website truly your own! Do you wanna hear more?",
  },
  contacts: {
    prompt: "Need to add more contacts?",
    header: "Exceeded free contact count",
    content: "Go beyond 1000 contacts for your Email Campaigns and upgrade to LiteSite Business!",
  },
};

export function Discovered() {
  const { source } = useParams() as { source: Sources };
  const backLocation = useBackLocation("/more");
  const content = Contents[source];

  return (
    <Overlay
      className={classNames(flexStyles.gap125, textStyles.center, paddingStyles.p125)}
      header={content.header}
      backTo={{ to: backLocation }}
    >
      <Animation className={classNames(iconStyles.width625, marginStyles.centered)} animationType="Dandelion" />
      <SubheaderMain>{content.prompt || "You discovered a LiteSite Business feature!"}</SubheaderMain>
      <TextMain>{content.content}</TextMain>
      <div className={classNames(flexStyles.vert100, marginStyles.mt250)}>
        <SmallTextMain>Starting at $16/month.</SmallTextMain>
        <Button to="/billing/upgrade">Continue</Button>
        <Button buttonType="Tertiary" to={backLocation}>
          Cancel
        </Button>
      </div>
    </Overlay>
  );
}
