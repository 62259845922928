import classNames from "classnames";
import {
  RawIcon,
  SubheaderMain,
  TextMain,
  bgStyles,
  flexStyles,
  marginStyles,
  paddingStyles,
  positionStyles,
  textStyles,
  widthStyles,
} from "ui";

export function UpgradeYourExistence() {
  return (
    <div
      className={classNames(
        flexStyles.vert050,
        flexStyles.alignCenter,
        bgStyles.blueUpgrade,
        textStyles.center,
        textStyles.neutral0,
        widthStyles.full,
        paddingStyles.p100,
        paddingStyles.pt600,
        positionStyles.relative,
        marginStyles.mtNegative575,
      )}
    >
      <RawIcon
        iconType="UpdateTwirl"
        className={classNames(positionStyles.absolute, positionStyles.left0, positionStyles.top0)}
      />
      <RawIcon
        iconType="UpdateTwirl2"
        className={classNames(positionStyles.absolute, positionStyles.right0, positionStyles.bottom0)}
      />
      <SubheaderMain>LiteSite Business</SubheaderMain>
      <TextMain className={positionStyles.relative}>Upgrade your online existence with LiteSite Business.</TextMain>
    </div>
  );
}
