import { useEffect } from "react";
import { Loader } from "ui";
import { PersistedContactGroup } from "schemas";
import { ContactsList } from "./ContactsList";
import { filterContacts } from "../../helpers/filterContacts";
import { searchContacts, useContactSearchResults } from "../../store";

interface Props {
  query: string;
  name?: string;
  contactGroup?: PersistedContactGroup;
  hideInitials?: boolean;
  disabledIds?: number[];
}
export function SearchContacts({ query, name, contactGroup, hideInitials, disabledIds = [] }: Props) {
  const contacts = useContactSearchResults(query);
  useEffect(() => {
    if (query) {
      searchContacts(query);
    }
  }, [query]);

  if (!query) {
    return null;
  }

  if (!contacts) {
    return <Loader />;
  }

  const contactsToRender = contactGroup ? filterContacts(contacts, contactGroup) : contacts;

  return (
    <ContactsList
      newContactTo="../contacts/new"
      disabledIds={disabledIds}
      hideInitials={hideInitials}
      contacts={contactsToRender}
      name={name}
      query={query}
    />
  );
}
