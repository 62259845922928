import { useFormContext, useWatch } from "react-hook-form";
import { RawCheckbox } from "ui";

interface Props {
  collection: { id: number }[];
  name: string;
  clearInitialValue?: number[];
}
export function AllCheckbox({ collection, name, clearInitialValue = [] }: Props) {
  const { setValue } = useFormContext();
  const selection = useWatch({ name });
  const selectionCount = selection ? selection.length : 0;
  const full = selectionCount == collection.length;
  const interrim = !full && selectionCount !== 0;

  const handler = () => {
    if (full) {
      setValue(name, clearInitialValue, { shouldDirty: true, shouldValidate: true });
    } else {
      setValue(
        name,
        collection.map((item) => item.id),
        { shouldDirty: true, shouldValidate: true },
      );
    }
  };

  return <RawCheckbox on={full} onClick={handler} interrim={interrim} />;
}
