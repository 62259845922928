import { Asserts, object, string } from "yup";

export const LinkBlockSchema = object({
  label: string().required("Label is required"),
  url: string().url("Link must be a valid url").required("Button has to link somewhere"),
  kind: string().oneOf(["LinkBlock"]).required(),
}).noUnknown();

export type LinkBlockPayload = Asserts<typeof LinkBlockSchema> & {
  kind: "LinkBlock";
};
export type PersistedLinkBlock = LinkBlockPayload & {
  _id: string;
  buttonUrl: string;
};
