import { ChangeEvent } from "react";
import classNames from "classnames";
import { heightStyles, opacityStyles, positionStyles, widthStyles } from "../../styles";

type FileInputType = "image" | "csv";
interface Props {
  onSelectFile: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  type: FileInputType;
}

const fileTypes: Record<FileInputType, string> = {
  image: ".png,.gif,.jpeg,.jpg,.webp,.heic,.heif",
  csv: ".csv",
};

export function FileInput({ onSelectFile, disabled = false, type }: Props) {
  return (
    <input
      className={classNames(widthStyles.full, heightStyles.full, opacityStyles.opacity0, positionStyles.absolute)}
      disabled={disabled}
      type="file"
      onChange={onSelectFile}
      accept={fileTypes[type]}
    />
  );
}
