"use client";

import ReactSelect from "react-select";
import { useFormContext, useWatch } from "react-hook-form";
import { Colors } from "schemas";
import { Label } from "./Label";
import { flexStyles } from "../styles";

type AvailableValues = string | number;
type SelectOption<T> = {
  label: string;
  value: T | undefined;
};

interface Props<T> {
  label: string;
  name: string;
  options: SelectOption<T>[];
}

export function Select<T extends AvailableValues>({ options, label, name }: Props<T>) {
  const { setValue } = useFormContext();
  const value = useWatch({ name });
  const selectValue = options.find((option: SelectOption<T>) => option.value === value);

  const onChange = (value: SelectOption<T> | null) => {
    setValue(name, value?.value, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <div className={flexStyles.vert025}>
      <Label name={name}>{label}</Label>
      <ReactSelect
        isSearchable={false}
        options={options}
        onChange={onChange}
        value={selectValue}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            minHeight: "3.125rem",
            boxShadow: "none",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0 0.875rem",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }),
          indicatorSeparator: () => ({ display: "none" }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            transform: state.isFocused ? "rotate(180deg)" : "",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          indicatorSeparator: () => ({ display: "none" }),
          colors: {
            ...theme.colors,
            primary: Colors.Secondary300,
            neutral20: Colors.Neutral400,
          },
        })}
      />
    </div>
  );
}
