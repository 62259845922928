import { Asserts, object, string } from "yup";

export const TextBlockSchema = object({
  text: string().required("Text is required"),
  kind: string().oneOf(["TextBlock"]).required(),
}).noUnknown();
export type TextBlockPayload = Asserts<typeof TextBlockSchema> & {
  kind: "TextBlock";
};
export type PersistedTextBlock = TextBlockPayload & {
  _id: string;
};
