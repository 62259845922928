import classNames from "classnames";
import { ToolbarButton } from "./ToolbarButton";
import { LinkButton } from "./LinkButton";
import { bgStyles, borderStyles, flexStyles, paddingStyles, positionStyles } from "../../styles";

export function ToolbarPlugin() {
  return (
    <div
      className={classNames(
        flexStyles.horiz050,
        bgStyles.editorBackground,
        borderStyles.radius025,
        positionStyles.absolute,
        positionStyles.top006,
        positionStyles.left006,
        positionStyles.right006,
        paddingStyles.py025,
        paddingStyles.px050,
      )}
    >
      <ToolbarButton type="bold" />
      <ToolbarButton type="italic" />
      <ToolbarButton type="unordered-list" />
      <ToolbarButton type="ordered-list" />
      <LinkButton />
    </div>
  );
}
