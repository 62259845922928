import { EmailBlockPayload, EmailBlockSchema } from "schemas";
import { FormProvider, FormTag, TextInput } from "ui";
import { Submit } from "../ui";

interface Props {
  initialValues: EmailBlockPayload;
  onSubmit: (data: EmailBlockPayload) => Promise<void>;
  cancelPath: string;
}

export function EmailForm({ initialValues, onSubmit, cancelPath }: Props) {
  return (
    <FormProvider<EmailBlockPayload> schema={EmailBlockSchema} defaultValues={initialValues}>
      <FormTag<EmailBlockPayload> onSubmit={onSubmit}>
        <TextInput autoFocus name="label" label="Button label" />
        <TextInput name="email" type="email" label="Email" />
        <Submit cancelTo={cancelPath} />
      </FormTag>
    </FormProvider>
  );
}
