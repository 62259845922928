import { BlockDestination, BlockPayload, PersistedInlineEditableBlock } from "schemas";
import { OnClickHandler } from "ui";
import { InlineEditor } from "./InlineEditor";
import { removeCampaignElement, removePageElement, updateBlock } from "../../../store";

interface Props {
  elementId: string;
  block: PersistedInlineEditableBlock;
  destination: BlockDestination;
  closeItself: OnClickHandler;
}
export function EditInlineBlock({ elementId, block, destination, closeItself }: Props) {
  const onSubmit = async (payload: BlockPayload) => {
    await updateBlock({ id: block._id, kind: block.kind, payload });
    closeItself();
  };

  const onDelete = async () => {
    if (window.confirm("Are you sure?")) {
      switch (destination.type) {
        case "Campaign":
          await removeCampaignElement({ campaignId: destination.entity.id, campaignElementId: elementId });
          break;
        case "Page":
          await removePageElement({ pageId: destination.entity.id, pageElementId: elementId });
          break;
      }
    }
  };

  return <InlineEditor block={block} onDelete={onDelete} onSubmit={onSubmit} onCancel={closeItself} />;
}
