import { EditableBlockKinds } from "schemas";

export const Titles: Record<EditableBlockKinds, string> = {
  AppointmentLinkBlock: "Edit Appointment Link Block",
  EmailBlock: "Edit Email Block",
  FormLinkBlock: "Edit Form Link Block",
  LinkBlock: "Edit Button Block",
  PhoneBlock: "Edit Phone Number Block",
  SocialLinksBlock: "Edit Social Links Block",
};
