import { Asserts, object, string } from "yup";
import { PhoneNumber } from "../shared";

export const PhoneBlockSchema = object({
  label: string().required("Label is required"),
  number: PhoneNumber.required("Phone number is required"),
  kind: string().oneOf(["PhoneBlock"]).required(),
}).noUnknown();
export type PhoneBlockPayload = Asserts<typeof PhoneBlockSchema> & {
  kind: "PhoneBlock";
};
export type PersistedPhoneBlock = PhoneBlockPayload & {
  _id: string;
  buttonUrl: string;
};
