"use client";

import { ReactNode, useEffect } from "react";
import { Path, PathValue, useFormContext } from "react-hook-form";
import { flexStyles, textStyles } from "../styles";
import { Error } from "./Error";
import { PlainText, PlainTextEditorSize } from "./Wysiwyg";
import { Label } from "./Label";

interface Props<T> {
  name: Path<T>;
  placeholder: string;
  size: PlainTextEditorSize;
  label?: string;
  extra?: ReactNode;
  onSubmit: (value: T) => Promise<void>;
}

export function InlineTextInput<T extends object>({ name, label, extra, size, placeholder, onSubmit }: Props<T>) {
  const { register, setValue, handleSubmit } = useFormContext<T>();

  useEffect(() => {
    register(name);
  }, [register, name]);

  return (
    <div className={flexStyles.vert050}>
      {label && (
        <Label className={textStyles.editorInterface} name={name} extra={extra}>
          {label}
        </Label>
      )}
      <PlainText
        size={size}
        name={name}
        setValue={(newValue, shouldValidate) => {
          setValue(name, newValue as PathValue<T, Path<T>>, { shouldDirty: true, shouldValidate });
        }}
        placeholder={placeholder}
        onSubmit={handleSubmit(onSubmit)}
      />
      <Error name={name} />
    </div>
  );
}
