import {
  FormProvider,
  FormTag,
  Loader,
  ResponsiveWrapper,
  SearchInput,
  TextAccent,
  flexStyles,
  paddingStyles,
} from "ui";
import { SelectContactsPayload, SelectContactsSchema } from "schemas";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSearchQuery } from "../../hooks";
import { ContactsList } from "../contacts/ContactsList";
import { Link, Overlay, SubmitButton, TransparentBottomWrapper } from "../ui";
import { ContactCounter } from "./ContactCounter";
import { SearchContacts } from "../contacts/SearchContacts";
import { addContactGroupMemberships, useContactGroup, useContacts } from "../../store";
import classNames from "classnames";
import { AllCheckbox } from "./AllCheckbox";
import { pluralize } from "../../helpers";

export function Add() {
  const navigate = useNavigate();
  const contactGroup = useContactGroup();
  const contacts = useContacts();
  const { searchQuery, setSearchQuery, searchQueryExists } = useSearchQuery();

  if (!contactGroup) {
    return <Loader />;
  }

  const contactGroupContactsCount = contactGroup.contacts?.length || 0;

  const onSubmit = async (payload: SelectContactsPayload) => {
    const contacts = payload.contacts || [];
    await addContactGroupMemberships({ contactGroupId: contactGroup.id, payload: { contacts } });
    toast.success(`Contacts successfully added to ${contactGroup.name}`);

    navigate(`/contactGroups/${contactGroup.id}`);
  };

  return (
    <FormProvider defaultValues={contactGroup} schema={SelectContactsSchema}>
      <Overlay
        full
        backTo={{ to: `/contactGroups/${contactGroup.id}`, label: `${contactGroup.name} List` }}
        header={`Edit ${contactGroup.name}`}
      >
        <ResponsiveWrapper className={classNames(paddingStyles.p125, flexStyles.vert, flexStyles.grow)}>
          <FormTag<SelectContactsPayload>
            className={classNames(paddingStyles.pb625, flexStyles.gap050, flexStyles.grow)}
            onSubmit={onSubmit}
          >
            <div className={classNames(flexStyles.horiz125, flexStyles.alignCenter)}>
              <SearchInput
                className={flexStyles.grow}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                value={searchQuery}
              />
              <Link to={`/contactGroups/${contactGroup.id}`}>Cancel</Link>
            </div>
            <div className={classNames(flexStyles.horiz125, flexStyles.alignCenter, paddingStyles.py075)}>
              <AllCheckbox clearInitialValue={contactGroup.contacts} name="contacts" collection={contacts} />
              <ContactCounter name="contacts">
                {(count) => <TextAccent className={flexStyles.grow}>Contacts ({count})</TextAccent>}
              </ContactCounter>
            </div>
            {searchQueryExists ? (
              <SearchContacts disabledIds={contactGroup.contacts} hideInitials query={searchQuery} name="contacts" />
            ) : (
              <ContactsList
                newContactTo="contacts/new"
                disabledIds={contactGroup.contacts}
                hideInitials
                contacts={contacts}
                name="contacts"
              />
            )}
            <TransparentBottomWrapper sticky>
              <ContactCounter offset={contactGroupContactsCount} name="contacts">
                {(count) => (
                  <SubmitButton
                    disableClean
                    label={count > 0 ? `Add ${count} ${pluralize("contact", count)}` : "Add"}
                  />
                )}
              </ContactCounter>
            </TransparentBottomWrapper>
          </FormTag>
        </ResponsiveWrapper>
      </Overlay>
    </FormProvider>
  );
}
