import { PersistedContactGroup } from "schemas";
import { useLiteSiteStore } from "../store";
import { setContactGroup } from "./setContactGroup";
import { useEffect } from "react";
import { apiRequest } from "../apiRequest";
import { useIdParam } from "../../hooks";

export async function getContactGroup(id: number) {
  const contactGroup = await apiRequest<PersistedContactGroup>({ url: `contactGroups/${id}` });
  setContactGroup(contactGroup);
}

export function useContactGroup(passedId?: number) {
  const paramId = useIdParam<number>("contactGroupId");
  const id = passedId || paramId;
  const contactGroup = useLiteSiteStore(({ contactGroups }) => contactGroups.get(Number(id)));
  useEffect(() => {
    if (id && !contactGroup) {
      getContactGroup(id);
    }
  }, [id, contactGroup]);

  return contactGroup;
}
