import {
  PersistedEmailBlock,
  PersistedFormLinkBlock,
  PersistedLinkBlock,
  PersistedPageBlock,
  PersistedPhoneBlock,
} from "schemas";
import classNames from "classnames";
import { Icon, IconType } from "../Icon";
import { Card } from "../Card";
import { TextAccent } from "../typography";
import { bgStyles, flexStyles, iconStyles, paddingStyles, pointerStyles, textStyles } from "../styles";

type ButtonBlock =
  | PersistedEmailBlock
  | PersistedPhoneBlock
  | PersistedLinkBlock
  | PersistedPageBlock
  | PersistedFormLinkBlock;

interface Props {
  block: ButtonBlock;
  editable: boolean;
  navigate: (path: string) => Promise<void>;
}

const IconTypes: Record<"EmailBlock" | "PhoneBlock" | "LinkBlock" | "PageBlock" | "FormLinkBlock", IconType> = {
  EmailBlock: "Mail",
  FormLinkBlock: "FormsSymbol",
  LinkBlock: "OpenInNew",
  PageBlock: "ChevronRight",
  PhoneBlock: "PhoneEnabled",
};

export function ButtonCard({ block, editable, navigate }: Props) {
  const label = block.label;
  const iconType = IconTypes[block.kind];
  const onClick = editable ? undefined : async () => navigate(block.buttonUrl);
  return (
    <Card
      className={classNames(
        textStyles.neutral500,
        flexStyles.horiz,
        flexStyles.justifyBetween,
        paddingStyles.p125,
        iconStyles.neutral300,
        {
          [iconStyles.neutral500]: !!onClick,
          [bgStyles.hoverNeutral200]: !!onClick,
          [pointerStyles.cursor]: !!onClick,
        },
      )}
      onClick={onClick}
    >
      <TextAccent>{label}</TextAccent>
      <Icon iconType={iconType} />
    </Card>
  );
}
