import { useEffect } from "react";
import { useLiteSiteStore } from "../store";
import { getCampaignElements } from "./getCampaignElements";

export function useCampaignElements(id?: string) {
  const campaignElements = useLiteSiteStore(({ campaignElements }) => (id ? campaignElements.get(id) : undefined));

  useEffect(() => {
    if (id) {
      getCampaignElements(id);
    }
  }, [id]);

  return campaignElements?.sort((elA, elB) => elA.position - elB.position);
}
