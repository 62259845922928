import {
  DotMenu,
  DotMenuLink,
  IconButton,
  PropsWithName,
  ResponsiveWrapper,
  TextMain,
  bgStyles,
  flexStyles,
  iconStyles,
  useConfirm,
} from "ui";
import { TransparentBottomWrapper } from "../ui";
import { useWatch } from "react-hook-form";
import classNames from "classnames";
import { batchBlockContacts, batchDeleteContacts, removeContactGroupMembershps, useContactGroup } from "../../store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { compact } from "es-toolkit";

interface Props extends PropsWithName {
  onDone: () => void;
}
export function SelectBottomBar({ name, onDone }: Props) {
  const contacts = useWatch({ name });
  const navigate = useNavigate();
  const count = contacts ? contacts.length : 0;
  const text = count ? `${count} contacts selected` : `Select contacts`;
  const contactGroup = useContactGroup();
  const { confirm, Confirm } = useConfirm();
  const disabled = count === 0;

  const onDelete = async () => {
    if (
      await confirm({
        text: `You're about to delete ${count} contacts, are you sure?`,
        label: `delete ${count} contacts`,
        onConfirm: async () => batchDeleteContacts(contacts),
      })
    ) {
      toast.success(`${count} contacts deleted`);
      onDone();
    }
  };

  const onBlock = async () => {
    if (
      await confirm({
        text: `Are you sure you want to block ${count} contacts?`,
        label: `Block ${count} contacts`,
        onConfirm: async () => batchBlockContacts(contacts),
      })
    ) {
      toast.success(`${count} contacts blocked`);
      onDone();
    }
  };

  const onAddToList = () => {
    const params = new URLSearchParams({ contactIds: contacts.join(",") });
    navigate(`addToList?${params.toString()}`);
  };

  const onRemoveFromList = async () => {
    if (
      contactGroup &&
      (await confirm({
        text: `Are you sure you want to remove ${count} contacts from ${contactGroup.name} ? `,
        label: `Remove ${count} contacts`,
        onConfirm: async () => {
          await removeContactGroupMembershps({
            contactGroupId: contactGroup.id,
            payload: {
              contacts,
            },
          });
        },
      }))
    ) {
      toast.success(`${count} contacts removed from the list`);
      onDone();
    }
  };

  const links: DotMenuLink[] = compact([
    { iconType: "Plus", label: "Add to list", onClick: onAddToList },
    { iconType: "Remove", label: "Block contacts", onClick: onBlock },
    contactGroup &&
      !contactGroup.autogeneratedType && {
        iconType: "ContactRemove",
        label: "Remove from this list",
        onClick: onRemoveFromList,
      },
    { iconType: "Delete", label: "Delete contacts", onClick: onDelete },
  ]);

  return (
    <TransparentBottomWrapper sticky className={bgStyles.neutral0}>
      <ResponsiveWrapper>
        <Confirm />
        <div
          className={classNames(flexStyles.horiz250, flexStyles.alignCenter, {
            [iconStyles.neutral500]: !disabled,
            [iconStyles.neutral300]: disabled,
          })}
        >
          <TextMain className={flexStyles.grow}>{text}</TextMain>
          <IconButton iconType="Delete" onClick={disabled ? undefined : onDelete} />
          <DotMenu links={links} disabled={disabled} />
        </div>
      </ResponsiveWrapper>
    </TransparentBottomWrapper>
  );
}
