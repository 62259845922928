"use client";

import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from "lexical";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { IconButton } from "../../IconButton";
import { IconType } from "../../Icon";
import styles from "./Toolbar.module.css";
import { LinkNode } from "@lexical/link";

type ToolbarButtonTypes = "bold" | "italic" | "unordered-list" | "ordered-list";
const Icons: Record<ToolbarButtonTypes, IconType> = {
  bold: "FormatBold",
  italic: "FormatItalic",
  "unordered-list": "FormatBulleted",
  "ordered-list": "FormatNumbered",
};
interface Props {
  type: ToolbarButtonTypes;
}
export function ToolbarButton({ type }: Props) {
  const [active, setActive] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [editor] = useLexicalComposerContext();

  const updateButton = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if (type === "unordered-list" || type === "ordered-list") {
          if ($isListNode(element)) {
            const parentList = $getNearestNodeOfType(anchorNode, ListNode);
            const listType = parentList ? parentList.getTag() : element.getTag();
            if (type === "ordered-list") {
              setActive(listType === "ol");
            }
            if (type === "unordered-list") {
              setActive(listType === "ul");
            }
          } else {
            setActive(false);
          }
        }
      }

      if (type === "bold" || type === "italic") {
        setDisabled(!!$getNearestNodeOfType(anchorNode, LinkNode));
        setActive(selection.hasFormat(type));
      }
    }
  }, [editor, type]);

  const onClick = () => {
    if (!disabled) {
      if (type === "bold" || type === "italic") {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, type);
      } else if (type === "unordered-list") {
        if (active) {
          editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        } else {
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        }
      } else if (type === "ordered-list") {
        if (active) {
          editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        } else {
          editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        }
      }
    }
  };

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateButton();
          });
        }),
      ),
    [editor, updateButton],
  );

  return (
    <IconButton
      className={classNames(styles.button, { [styles.active]: active })}
      square
      onClick={onClick}
      iconType={Icons[type]}
    />
  );
}
