import { PersistedCampaign } from "schemas";
import { useLiteSiteStore } from "../store";
import { HookLoading } from "../types";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { setCampaign } from "./setCampaign";
import { apiRequest } from "../apiRequest";

async function getCampaigns() {
  const campaigns = await apiRequest<PersistedCampaign[]>({ url: "campaigns" });
  campaigns.map(setCampaign);
}

export function useCampaigns(): HookLoading<{ campaigns: PersistedCampaign[] }> {
  const [loading, setLoading] = useState(true);
  const campaigns = useLiteSiteStore((state) => Array.from(state.campaigns.values())).sort(
    (a, b) => DateTime.fromISO(b.insertedAt).diff(DateTime.fromISO(a.insertedAt)).milliseconds,
  );

  useEffect(() => {
    if (loading) {
      getCampaigns().then(() => setLoading(false));
    }
  }, [loading]);

  return { loading, campaigns };
}
