import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useLocation, useNavigate } from "react-router-dom";
import { BlockCard, flexStyles } from "ui";
import {
  BlockDestination,
  InlineEditableBlockKindValues,
  PersistedElement,
  PersistedInlineEditableBlock,
} from "schemas";
import classNames from "classnames";
import { linksForElement } from "../../helpers/linksForElement";
import { useBlock } from "../../store";
import { EditInlineBlock } from "../ui/InlineEditors";
import { EditWrapper } from "./EditWrapper";
import { useBlockKindParam, useIdParam } from "../../hooks";

interface Props {
  last: boolean;
  destination: BlockDestination;
  element: PersistedElement;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null;
}
export function BlockElement({ last, element, destination, dragHandleProps }: Props) {
  const { id, position, kind, block: elementBlock } = element;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const block = useBlock(kind, elementBlock._id);
  const openBlockId = useIdParam("blockId");
  const pathKind = useBlockKindParam();
  const drawerHack = pathname.match(/new$/);
  const hasDrawer = drawerHack && drawerHack.length > 0;

  if (!block) {
    return null;
  }

  const open = block._id === openBlockId && kind === pathKind && !hasDrawer;
  const onClick = () => {
    const to = open ? "." : `blocks/${block.kind}/${block._id}`;
    navigate(to, { relative: "path", preventScrollReset: true });
  };

  const editElement =
    open && InlineEditableBlockKindValues.includes(block.kind) ? (
      <EditInlineBlock
        elementId={element.id}
        block={block as PersistedInlineEditableBlock}
        destination={destination}
        closeItself={() => navigate(".")}
      />
    ) : undefined;

  return (
    <EditWrapper
      className={classNames(flexStyles.alignStretch)}
      index={position}
      last={last}
      kind={kind}
      blockId={block._id}
      dragHandleProps={dragHandleProps}
      links={linksForElement(destination, block, element.id, navigate)}
      open={open}
      onClick={onClick}
      editElement={editElement}
    >
      <BlockCard
        key={id}
        block={block}
        editable={!!onClick}
        lazy={false}
        navigate={async (path: string) => {
          navigate(path);
        }}
      />
    </EditWrapper>
  );
}
