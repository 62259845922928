import { Icon, TextAccent, flexStyles } from "ui";
import { Button } from "../ui";
import classNames from "classnames";

interface Props {
  count: number;
}
export function Panel({ count }: Props) {
  return (
    <div className={classNames(flexStyles.horiz050, flexStyles.alignCenter)}>
      <TextAccent className={flexStyles.grow}>Contacts ({count})</TextAccent>
      <Button buttonType="Secondary" to="search">
        <Icon iconType="SearchSymbol" />
      </Button>
      <Button buttonType="Secondary" to="add">
        <Icon iconType="Plus" />
      </Button>
      <Button buttonType="Secondary" to="select">
        Select
      </Button>
    </div>
  );
}
