import { RawIcon } from "../../Icon";
import { displayStyles } from "../../styles";
import { PropsWithOptionalClick } from "../../types";

interface Props extends PropsWithOptionalClick {
  on: boolean;
  interrim?: boolean;
  name?: string;
}
export function RawCheckbox({ on, name, interrim, onClick }: Props) {
  const checkIconType = on ? "CheckboxChecked" : "CheckboxUnchecked";
  const iconType = interrim ? "CheckboxInterrim" : checkIconType;

  return (
    <label className={displayStyles.block} htmlFor={name} onClick={onClick}>
      <RawIcon iconType={iconType} />
    </label>
  );
}
