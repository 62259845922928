import { LinkBlockPayload, LinkBlockSchema } from "schemas";
import { FormProvider, FormTag, TextInput } from "ui";
import { Submit } from "../ui";

interface Props {
  initialValues: LinkBlockPayload;
  onSubmit: (data: LinkBlockPayload) => Promise<void>;
  cancelPath: string;
}

export function LinkForm({ initialValues, onSubmit, cancelPath }: Props) {
  return (
    <FormProvider<LinkBlockPayload> schema={LinkBlockSchema} defaultValues={initialValues}>
      <FormTag<LinkBlockPayload> onSubmit={onSubmit}>
        <TextInput autoFocus name="label" label="Button label" />
        <TextInput name="url" type="url" label="Button link" />
        <Submit cancelTo={cancelPath} />
      </FormTag>
    </FormProvider>
  );
}
