import { toast } from "react-toastify";
import { BlockDestination, PersistedBlock, PersistedPage } from "schemas";
import { PopoverLinkItem } from "ui";
import {
  deleteAppointment,
  getAppointment,
  removeCampaignElement,
  removePage,
  removePageElement,
  updateAppointment,
  useLiteSiteStore,
} from "../store";
import { getPageElements } from "../store/pages/getPageElements";

export function linksForElement(
  destination: BlockDestination,
  block: PersistedBlock,
  elementId: string,
  navigate: (path: string) => void,
): PopoverLinkItem[] {
  if (block.kind === "AppointmentBlock") {
    const { appointmentId } = block;
    getAppointment(appointmentId);
    const showHideLink: PopoverLinkItem = block.public
      ? {
          iconType: "Hide",
          onClick: async () => {
            const appointment = useLiteSiteStore.getState().appointments.get(appointmentId)!;
            await updateAppointment(appointmentId, { ...appointment, public: false });
            getPageElements(destination.entity.id);
            toast("Block hidden");
          },
          label: "Hide Block",
        }
      : {
          iconType: "Show",
          onClick: async () => {
            const appointment = useLiteSiteStore.getState().appointments.get(appointmentId)!;
            await updateAppointment(appointmentId, { ...appointment, public: true });
            getPageElements(destination.entity.id);
            toast("Block visible");
          },
          label: "Show Block",
        };
    return [
      {
        label: "Preview Appointment",
        onClick: async () => {
          window.open(block.buttonUrl);
        },
        iconType: "Show",
      },
      {
        label: "Edit",
        onClick: async () => {
          navigate(`/appointments/${appointmentId}`);
        },
        iconType: "Edit",
      },
      {
        iconType: "EditCalendar",
        onClick: async () => {
          navigate(`/appointments/${appointmentId}/schedule`);
        },
        label: "Edit Availability",
      },
      {
        iconType: "Payments",
        onClick: async () => {
          navigate(`/appointments/${appointmentId}/utilities`);
        },
        label: "Edit Utilities",
      },
      {
        iconType: "Automations",
        onClick: async () => {
          navigate(`/appointments/${appointmentId}/automation`);
        },
        label: "Edit Automations",
      },
      showHideLink,
      {
        iconType: "Delete",
        onClick: async () => {
          window.confirm("Are you sure?") &&
            (await deleteAppointment(destination.entity as PersistedPage, appointmentId));
        },
        label: "Delete Appointment",
      },
    ];
  }
  if (block.kind === "PageBlock") {
    return [
      {
        label: "Preview Page",
        onClick: async () => {
          window.open(block.url);
        },
        iconType: "Show",
      },
      {
        label: "Edit",
        onClick: async () => {
          navigate(`/pages/${block.page}`);
        },
        iconType: "Edit",
      },
      {
        label: "Delete",
        onClick: async () => {
          window.confirm("Are you sure?") && (await removePage(destination.entity as PersistedPage, block.page));
        },
        iconType: "Delete",
      },
    ];
  }
  return [
    {
      label: "Edit",
      onClick: () => navigate(`blocks/edit/${block.kind}/${block._id}`),
      iconType: "Edit",
    },
    {
      label: "Delete",
      onClick: async () => {
        if (window.confirm("Are you sure?")) {
          switch (destination.type) {
            case "Campaign":
              await removeCampaignElement({ campaignId: destination.entity.id, campaignElementId: elementId });
              break;
            case "Page":
              await removePageElement({ pageId: destination.entity.id, pageElementId: elementId });
              break;
          }
        }
      },
      iconType: "Delete",
    },
  ];
}
