import { useKey } from "react-use";
import { SearchItem } from "./AutoCompleteInput";
import { Card } from "../../Card";
import { Strong, TextMain } from "../../typography";
import { flexStyles, heightStyles, overflowStyles, positionStyles, textStyles } from "../../styles";
import classNames from "classnames";
import { Row } from "./Row";

interface Props {
  search: string;
  searchResults: SearchItem[];
  onClick: (id: number) => Promise<void>;
  onAddNew?: (query: string) => Promise<void>;
}

export function Drawer({ search, searchResults, onClick, onAddNew }: Props) {
  const isEmail = search.match(/.+@.+\..+/);

  useKey(
    "Enter",
    (e) => {
      e.preventDefault();

      if (isEmail) {
        onAddNew && onAddNew(search);
      } else if (searchResults.length > 0) {
        onClick(searchResults[0].id);
      }
    },
    {},
    [search, searchResults, isEmail],
  );

  return (
    <Card
      className={classNames(
        flexStyles.gap0,
        overflowStyles.autoY,
        heightStyles.maxHalf,
        positionStyles.absolute,
        positionStyles.top100Percent,
        positionStyles.left0,
        positionStyles.right0,
        positionStyles.zIndex47,
      )}
    >
      {searchResults.length === 0 &&
        (isEmail && onAddNew ? (
          <Row onClick={onAddNew && (() => onAddNew(search))}>
            <TextMain>
              Add <Strong>{search}</Strong> as a recipient
            </TextMain>
          </Row>
        ) : (
          <TextMain>
            No results for <Strong>{search}</Strong>.{onAddNew && " Complete the email address to add a recipient."}
          </TextMain>
        ))}
      {searchResults.map((item) => (
        <Row onClick={() => onClick(item.id)} key={item.id}>
          <TextMain>{item.label}</TextMain>
          {item.subLabel && (
            <>
              •<TextMain className={textStyles.neutral400}>{item.subLabel}</TextMain>
            </>
          )}
        </Row>
      ))}
    </Card>
  );
}
