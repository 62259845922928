import { FC } from "react";
import classNames from "classnames";
import styles from "./Icon.module.css";
import { PropsWithClassName } from "./types";

import Account from "../svg/Account";
import Add from "../svg/Add";
import AddButtons from "../svg/AddButtons";
import AddImage from "../svg/AddImage";
import Appearance from "../svg/Appearance";
import ArrowLeft from "../svg/ArrowLeft";
import ArrowRight from "../svg/ArrowRight";
import AuthStarBottom from "../svg/AuthStarBottom";
import AuthStarTop from "../svg/AuthStarTop";
import AuthUnderline from "../svg/AuthUnderline";
import Automations from "../svg/Automations";
import BackArrow from "../svg/BackArrow";
import Basics from "../svg/Basics";
import BillingDownArrow from "../svg/BillingDownArrow";
import BillingUpArrow from "../svg/BillingUpArrow";
import Buttons from "../svg/Buttons";
import Calendar from "../svg/Calendar";
import CalendarMonth from "../svg/CalendarMonth";
import CalendarPrimary from "../svg/CalendarPrimary";
import CalendarSettings from "../svg/CalendarSettings";
import Check from "../svg/Check";
import CheckboxChecked from "../svg/CheckboxChecked";
import CheckboxInterrim from "../svg/CheckboxInterrim";
import CheckboxUnchecked from "../svg/CheckboxUnchecked";
import ChevronDown from "../svg/ChevronDown";
import ChevronRight from "../svg/ChevronRight";
import ChevronSmallDown from "../svg/ChevronSmallDown";
import ChevronSmallLeft from "../svg/ChevronSmallLeft";
import ChevronSmallRight from "../svg/ChevronSmallRight";
import ChevronSmallUp from "../svg/ChevronSmallUp";
import ChevronUp from "../svg/ChevronUp";
import ContactGroup from "../svg/ContactGroup";
import ContactRemove from "../svg/ContactRemove";
import Copy from "../svg/Copy";
import CsvExample from "../svg/CsvExample";
import CustomersLowerLeft from "../svg/CustomersLowerLeft";
import CustomersSquiggle from "../svg/CustomersSquiggle";
import CustomersUpperLeft from "../svg/CustomersUpperLeft";
import CustomersUpperRight from "../svg/CustomersUpperRight";
import CutOutLeft from "../svg/CutOutLeft";
import CutOutRight from "../svg/CutOutRight";
import Delete from "../svg/Delete";
import Diamond from "../svg/Diamond";
import Dollars from "../svg/Dollars";
import DontWaitSquiggleBlue from "../svg/DontWaitSquiggleBlue";
import DontWaitSquiggleRed from "../svg/DontWaitSquiggleRed";
import Drag from "../svg/Drag";
import Edit from "../svg/Edit";
import EditCalendar from "../svg/EditCalendar";
import EmailNewsletter from "../svg/EmailNewsletter";
import EmailUnsubscribe from "../svg/EmailUnsubscribe";
import Empty from "../svg/Empty";
import Error from "../svg/Error";
import Event from "../svg/Event";
import EventCheck from "../svg/EventCheck";
import EventCanceled from "../svg/EventCanceled";
import EventUpcoming from "../svg/EventUpcoming";
import Feedback from "../svg/Feedback";
import FileCsv from "../svg/FileCsv";
import FinalBigBottomSquiggle from "../svg/FinalBigBottomSquiggle";
import FinalSquiggleLeft from "../svg/FinalSquiggleLeft";
import FinalSquiggleRight from "../svg/FinalSquiggleRight";
import FinalSquiggleTop from "../svg/FinalSquiggleTop";
import FinalSquiggleBottom from "../svg/FinalSquiggleBottom";
import FollowUp from "../svg/FollowUp";
import FormatBold from "../svg/FormatBold";
import FormatBulleted from "../svg/FormatBulleted";
import FormatHeading from "../svg/FormatHeading";
import FormatItalic from "../svg/FormatItalic";
import FormatNumbered from "../svg/FormatNumbered";
import FormsSymbol from "../svg/FormsSymbol";
import FormDateField from "../svg/FormDateField";
import FormDateFieldBox from "../svg/FormDateFieldBox";
import FormMultipleChoiceField from "../svg/FormMultipleChoiceField";
import FormNumberField from "../svg/FormNumberField";
import FormNumberFieldBox from "../svg/FormNumberFieldBox";
import FormTextField from "../svg/FormTextField";
import FormTextFieldBox from "../svg/FormTextFieldBox";
import FormFileField from "../svg/FormFileField";
import FormFileFieldBox from "../svg/FormFileFieldBox";
import FormSingleChoiceField from "../svg/FormSingleChoiceField";
import FunkyWrap from "../svg/FunkyWrap";
import GetYoursStars from "../svg/GetYoursStars";
import GetYoursTwirl from "../svg/GetYoursTwirl";
import GoogleCalendar from "../svg/GoogleCalendar";
import GoogleContacts from "../svg/GoogleContacts";
import GoogleMeet from "../svg/GoogleMeet";
import Hamburger from "../svg/Hamburger";
import Help from "../svg/Help";
import HeroArrow from "../svg/HeroArrow";
import HeroInstagram from "../svg/HeroInstagram";
import HeroLines from "../svg/HeroLines";
import HeroTikTok from "../svg/HeroTikTok";
import HeroTwitter from "../svg/HeroTwitter";
import HeroWhatsapp from "../svg/HeroWhatsapp";
import Hide from "../svg/Hide";
import IcsFile from "../svg/IcsFile";
import Image from "../svg/Image";
import Info from "../svg/Info";
import InPerson from "../svg/InPerson";
import Link from "../svg/Link";
import Location from "../svg/Location";
import Lock from "../svg/Lock";
import Logo from "../svg/Logo";
import Logout from "../svg/Logout";
import MagicWand from "../svg/MagicWand";
import Mail from "../svg/Mail";
import MultipleCalendars from "../svg/MultipleCalendar";
import Note from "../svg/Note";
import Online from "../svg/Online";
import OpenInNew from "../svg/OpenInNew";
import OutlookCalendar from "../svg/OutlookCalendar";
import PageBlock from "../svg/PageBlock";
import Paragraph from "../svg/Paragraph";
import Payments from "../svg/Payments";
import PaymentsStarLeft from "../svg/PaymentsStarLeft";
import PaymentsStarRight from "../svg/PaymentsStarRight";
import PaymentsStarTopRight from "../svg/PaymentsStarTopRight";
import Pending from "../svg/Pending";
import PhoneEnabled from "../svg/PhoneEnabled";
import Plus from "../svg/Plus";
import RadioChecked from "../svg/RadioChecked";
import RadioUnchecked from "../svg/RadioUnchecked";
import Refresh from "../svg/Refresh";
import Reminder from "../svg/Reminder";
import Remove from "../svg/Remove";
import RemoveLiteSite from "../svg/RemoveLiteSite";
import SearchSymbol from "../svg/SearchSymbol";
import Settings from "../svg/Settings";
import Share from "../svg/Share";
import Show from "../svg/Show";
import Shuffle from "../svg/Shuffle";
import SMSMarketing from "../svg/SmsMarketing";
import SocialAmazon from "../svg/SocialAmazon";
import SocialBandcamp from "../svg/SocialBandcamp";
import SocialBehance from "../svg/SocialBehance";
import SocialDeviantArt from "../svg/SocialDeviantArt";
import SocialDiscord from "../svg/SocialDiscord";
import SocialDribbble from "../svg/SocialDribbble";
import SocialFacebook from "../svg/SocialFacebook";
import SocialFigma from "../svg/SocialFigma";
import SocialGithub from "../svg/SocialGithub";
import SocialGoogle from "../svg/SocialGoogle";
import SocialInstagram from "../svg/SocialInstagram";
import SocialLine from "../svg/SocialLine";
import SocialLinkedin from "../svg/SocialLinkedin";
import SocialMedium from "../svg/SocialMedium";
import SocialMessenger from "../svg/SocialMessenger";
import SocialMySpace from "../svg/SocialMySpace";
import SocialPinterest from "../svg/SocialPinterest";
import SocialQQ from "../svg/SocialQq";
import SocialReddit from "../svg/SocialReddit";
import SocialSignal from "../svg/SocialSignal";
import SocialSkype from "../svg/SocialSkype";
import SocialSlack from "../svg/SocialSlack";
import SocialSnapchat from "../svg/SocialSnapchat";
import SocialSpotify from "../svg/SocialSpotify";
import SocialTeams from "../svg/SocialTeams";
import SocialTelegram from "../svg/SocialTelegram";
import SocialThreads from "../svg/SocialThreads";
import SocialTumblr from "../svg/SocialThumblr";
import SocialTiktok from "../svg/SocialTiktok";
import SocialTinder from "../svg/SocialTinder";
import SocialTwitch from "../svg/SocialTwitch";
import SocialTwitter from "../svg/SocialTwitter";
import SocialViber from "../svg/SocialViber";
import SocialVimeo from "../svg/SocialVimeo";
import SocialVK from "../svg/SocialVk";
import SocialWeChat from "../svg/SocialWeChat";
import SocialWhatsapp from "../svg/SocialWhatsapp";
import SocialYoutube from "../svg/SocialYoutube";
import StripeLogo from "../svg/StripeLogo";
import Success from "../svg/Success";
import SyncCalendar from "../svg/SyncCalendar";
import TaskAlt from "../svg/TaskAlt";
import TestimonialChevronLeft from "../svg/TestimonialChevronLeft";
import TestimonialChevronRight from "../svg/TestimonialChevronRight";
import TextFields from "../svg/TextFields";
import Thanks from "../svg/Thanks";
import ThreeDots from "../svg/ThreeDots";
import Time from "../svg/Time";
import Timer from "../svg/Timer";
import Timezone from "../svg/Timezone";
import UpdateTwirl from "../svg/UpdateTwirl";
import UpdateTwirl2 from "../svg/UpdateTwirl2";
import UpgradeBadge from "../svg/UpgradeBadge";
import UploadCloud from "../svg/UploadCloud";
import Video from "../svg/Video";
import WhatsApp from "../svg/Whatsapp";
import X from "../svg/Close";
import YahooCalendar from "../svg/YahooCalendar";
import Zoom from "../svg/Zoom";
import ZoomLogo from "../svg/ZoomLogo";
import { flexStyles } from "./styles";

export type IconType =
  | "Account"
  | "Add"
  | "AddButtons"
  | "AddImage"
  | "Appearance"
  | "ArrowLeft"
  | "ArrowRight"
  | "AuthStarBottom"
  | "AuthStarTop"
  | "AuthUnderline"
  | "Automations"
  | "BackArrow"
  | "Basics"
  | "BillingDownArrow"
  | "BillingUpArrow"
  | "Buttons"
  | "Calendar"
  | "CalendarMonth"
  | "CalendarPrimary"
  | "CalendarSettings"
  | "Check"
  | "CheckboxChecked"
  | "CheckboxInterrim"
  | "CheckboxUnchecked"
  | "ChevronDown"
  | "ChevronRight"
  | "ChevronSmallDown"
  | "ChevronSmallLeft"
  | "ChevronSmallRight"
  | "ChevronSmallUp"
  | "ChevronUp"
  | "ContactGroup"
  | "ContactRemove"
  | "Copy"
  | "CsvExample"
  | "CustomersLowerLeft"
  | "CustomersSquiggle"
  | "CustomersUpperLeft"
  | "CustomersUpperRight"
  | "CutOutLeft"
  | "CutOutRight"
  | "Delete"
  | "Diamond"
  | "Dollars"
  | "DontWaitSquiggleBlue"
  | "DontWaitSquiggleRed"
  | "Drag"
  | "Edit"
  | "EditCalendar"
  | "EmailNewsletter"
  | "EmailUnsubscribe"
  | "Empty"
  | "Error"
  | "Event"
  | "EventCanceled"
  | "EventCheck"
  | "EventUpcoming"
  | "Feedback"
  | "FileCsv"
  | "FinalBigBottomSquiggle"
  | "FinalSquiggleLeft"
  | "FinalSquiggleRight"
  | "FinalSquiggleTop"
  | "FinalSquiggleBottom"
  | "FollowUp"
  | "FormatBold"
  | "FormatBulleted"
  | "FormatHeading"
  | "FormatItalic"
  | "FormatNumbered"
  | "FormsSymbol"
  | "FormDateField"
  | "FormDateFieldBox"
  | "FormMultipleChoiceField"
  | "FormNumberField"
  | "FormNumberFieldBox"
  | "FormTextField"
  | "FormTextFieldBox"
  | "FormFileField"
  | "FormFileFieldBox"
  | "FormSingleChoiceField"
  | "FunkyWrap"
  | "GetYoursStars"
  | "GetYoursTwirl"
  | "GoogleCalendar"
  | "GoogleContacts"
  | "GoogleMeet"
  | "Hamburger"
  | "Help"
  | "HeroArrow"
  | "HeroInstagram"
  | "HeroLines"
  | "HeroTikTok"
  | "HeroTwitter"
  | "HeroWhatsapp"
  | "Hide"
  | "IcsFile"
  | "Image"
  | "Info"
  | "InPerson"
  | "Link"
  | "Location"
  | "Lock"
  | "Logo"
  | "Logout"
  | "MagicWand"
  | "Mail"
  | "MultipleCalendars"
  | "Note"
  | "Online"
  | "OpenInNew"
  | "OutlookCalendar"
  | "PageBlock"
  | "Paragraph"
  | "Payments"
  | "PaymentsStarLeft"
  | "PaymentsStarRight"
  | "PaymentsStarTopRight"
  | "Pending"
  | "PhoneEnabled"
  | "Plus"
  | "RadioChecked"
  | "RadioUnchecked"
  | "Refresh"
  | "Reminder"
  | "Remove"
  | "RemoveLiteSite"
  | "SearchSymbol"
  | "Settings"
  | "Share"
  | "Show"
  | "Shuffle"
  | "SMSMarketing"
  | "SocialAmazon"
  | "SocialBandcamp"
  | "SocialBehance"
  | "SocialDeviantArt"
  | "SocialDiscord"
  | "SocialDribbble"
  | "SocialFacebook"
  | "SocialFigma"
  | "SocialGithub"
  | "SocialGoogle"
  | "SocialInstagram"
  | "SocialLine"
  | "SocialLinkedin"
  | "SocialMedium"
  | "SocialMessenger"
  | "SocialMySpace"
  | "SocialPinterest"
  | "SocialQQ"
  | "SocialReddit"
  | "SocialSignal"
  | "SocialSkype"
  | "SocialSlack"
  | "SocialSnapchat"
  | "SocialSpotify"
  | "SocialTeams"
  | "SocialTelegram"
  | "SocialThreads"
  | "SocialTumblr"
  | "SocialTiktok"
  | "SocialTinder"
  | "SocialTwitch"
  | "SocialTwitter"
  | "SocialViber"
  | "SocialVimeo"
  | "SocialVK"
  | "SocialWeChat"
  | "SocialWhatsapp"
  | "SocialYoutube"
  | "StripeLogo"
  | "Success"
  | "SyncCalendar"
  | "TaskAlt"
  | "TestimonialChevronLeft"
  | "TestimonialChevronRight"
  | "TextFields"
  | "Thanks"
  | "ThreeDots"
  | "Time"
  | "Timer"
  | "Timezone"
  | "UpdateTwirl"
  | "UpdateTwirl2"
  | "UpgradeBadge"
  | "UploadCloud"
  | "Video"
  | "WhatsApp"
  | "X"
  | "YahooCalendar"
  | "Zoom"
  | "ZoomLogo";

const Icons: Record<IconType, FC> = {
  Account,
  AddButtons,
  AddImage,
  Add,
  Appearance,
  ArrowLeft,
  ArrowRight,
  AuthStarBottom,
  AuthStarTop,
  AuthUnderline,
  Automations,
  BackArrow,
  Basics,
  BillingDownArrow,
  BillingUpArrow,
  Buttons,
  CalendarMonth,
  CalendarPrimary,
  CalendarSettings,
  Calendar,
  Check,
  CheckboxChecked,
  CheckboxInterrim,
  CheckboxUnchecked,
  ChevronDown,
  ChevronRight,
  ChevronSmallDown,
  ChevronSmallLeft,
  ChevronSmallRight,
  ChevronSmallUp,
  ChevronUp,
  ContactGroup,
  ContactRemove,
  Copy,
  CsvExample,
  CustomersLowerLeft,
  CustomersSquiggle,
  CustomersUpperLeft,
  CustomersUpperRight,
  CutOutLeft,
  CutOutRight,
  Delete,
  Diamond,
  Dollars,
  DontWaitSquiggleBlue,
  DontWaitSquiggleRed,
  Drag,
  Edit,
  EditCalendar,
  EmailNewsletter,
  EmailUnsubscribe,
  Empty,
  Error,
  Event,
  EventCanceled,
  EventCheck,
  EventUpcoming,
  Feedback,
  FileCsv,
  FinalBigBottomSquiggle,
  FinalSquiggleTop,
  FinalSquiggleRight,
  FinalSquiggleLeft,
  FinalSquiggleBottom,
  FollowUp,
  FormatBold,
  FormatBulleted,
  FormatHeading,
  FormatItalic,
  FormatNumbered,
  FormsSymbol,
  FormDateFieldBox,
  FormDateField,
  FormMultipleChoiceField,
  FormNumberFieldBox,
  FormNumberField,
  FormTextFieldBox,
  FormTextField,
  FormFileFieldBox,
  FormFileField,
  FormSingleChoiceField,
  FunkyWrap,
  GetYoursStars,
  GetYoursTwirl,
  GoogleCalendar,
  GoogleContacts,
  GoogleMeet,
  Hamburger,
  Help,
  HeroArrow,
  HeroInstagram,
  HeroLines,
  HeroTikTok,
  HeroTwitter,
  HeroWhatsapp,
  Hide,
  IcsFile,
  Image,
  Info,
  InPerson,
  Link,
  Location,
  Lock,
  Logo,
  Logout,
  MagicWand,
  Mail,
  MultipleCalendars,
  Note,
  Online,
  OpenInNew,
  OutlookCalendar,
  PageBlock,
  Paragraph,
  Payments,
  PaymentsStarLeft,
  PaymentsStarRight,
  PaymentsStarTopRight,
  Pending,
  PhoneEnabled,
  Plus,
  RadioChecked,
  RadioUnchecked,
  Refresh,
  Reminder,
  Remove,
  RemoveLiteSite,
  SearchSymbol,
  Settings,
  Share,
  Show,
  Shuffle,
  SMSMarketing,
  SocialAmazon,
  SocialBandcamp,
  SocialBehance,
  SocialDeviantArt,
  SocialDiscord,
  SocialDribbble,
  SocialFacebook,
  SocialFigma,
  SocialGithub,
  SocialGoogle,
  SocialInstagram,
  SocialLine,
  SocialLinkedin,
  SocialMedium,
  SocialMessenger,
  SocialMySpace,
  SocialPinterest,
  SocialQQ,
  SocialReddit,
  SocialSignal,
  SocialSkype,
  SocialSlack,
  SocialSnapchat,
  SocialSpotify,
  SocialTeams,
  SocialTelegram,
  SocialThreads,
  SocialTumblr,
  SocialTiktok,
  SocialTinder,
  SocialTwitch,
  SocialTwitter,
  SocialViber,
  SocialVimeo,
  SocialVK,
  SocialWeChat,
  SocialWhatsapp,
  SocialYoutube,
  StripeLogo,
  Success,
  SyncCalendar,
  TaskAlt,
  TestimonialChevronLeft,
  TestimonialChevronRight,
  TextFields,
  Thanks,
  ThreeDots,
  Time,
  Timer,
  Timezone,
  UpdateTwirl,
  UpdateTwirl2,
  UpgradeBadge,
  UploadCloud,
  Video,
  WhatsApp,
  X,
  YahooCalendar,
  Zoom,
  ZoomLogo,
};

interface Props extends PropsWithClassName {
  iconType: IconType;
}

export function RawIcon({ className, iconType }: Props) {
  const IconComponent = Icons[iconType];
  return (
    <div className={classNames(flexStyles.vert, flexStyles.justifyCenter, className)}>
      <IconComponent />
    </div>
  );
}

export function Icon({ className, iconType }: Props) {
  return <RawIcon className={classNames(styles.icon, className)} iconType={iconType} />;
}
