import { PersistedTextBlock } from "schemas";
import { Markdown } from "../typography";
import { widthStyles } from "../styles";

interface Props {
  block: PersistedTextBlock;
}
export function TextBlockCard({ block }: Props) {
  return <Markdown className={widthStyles.full}>{block.text}</Markdown>;
}
