import { flexStyles, marginStyles } from "../styles";
import { SmallContactInitials } from "./SmallContactInitials";
import classNames from "classnames";
import { SmallCircle } from "./SmallCircle";
import { useId } from "react";
import { InitialsSelection } from "schemas";

interface Props {
  initials: InitialsSelection;
}
export function SmallInitialsGroup({ initials: { selection, total } }: Props) {
  const extra = total - selection.length;
  const id = useId();

  return (
    <div className={flexStyles.horiz}>
      {selection.map((initials, index) => (
        <SmallContactInitials
          key={`smi-${id}-${index}`}
          className={marginStyles.msNegative075}
          contact={{ initials }}
        />
      ))}
      {extra > 0 && <SmallCircle className={classNames(marginStyles.msNegative075)} text={`+${extra}`} />}
    </div>
  );
}
