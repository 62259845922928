import { GrayGrow, ResponsiveWrapper, flexStyles, heightStyles, paddingStyles } from "ui";
import { NoResults } from "../contacts/NoResults";
import classNames from "classnames";
import { Outlet } from "react-router-dom";
import { useContactGroups } from "../../store";

export function AddYourFirstContact() {
  const { contactGroups } = useContactGroups();
  const allContactsGroup = contactGroups.find((contactGroup) => contactGroup.autogeneratedType === "AllContacts");
  return (
    <GrayGrow>
      <ResponsiveWrapper className={classNames(paddingStyles.p125, flexStyles.vert, heightStyles.full)}>
        <NoResults newContactTo={`/contactGroups/${allContactsGroup?.id}/contacts/new`} />
      </ResponsiveWrapper>
      <Outlet />
    </GrayGrow>
  );
}
