import { forwardRef } from "react";
import classNames from "classnames";
import { PropsWithClassNameAndChildren } from "./types";
import { bgStyles, borderStyles, flexStyles, pointerStyles } from "./styles";

interface Props extends PropsWithClassNameAndChildren {
  onClick?: () => void;
}
export const Card = forwardRef<HTMLDivElement, Props>(({ className, onClick, children }: Props, ref) => (
  <div
    dir="auto"
    className={classNames(
      flexStyles.vert050,
      flexStyles.justifyStretch,
      borderStyles.radius025,
      borderStyles.neutral200,
      bgStyles.neutral0,
      className,
      {
        [pointerStyles.cursor]: !!onClick,
      },
    )}
    onClick={onClick}
    ref={ref}
  >
    {children}
  </div>
));
