import { Edit, Fonts } from "../components/themes";

export const OutletThemeRoutes = [
  {
    path: "theme/:themeId",
    children: [
      { path: "", Component: Edit },
      { path: "font", Component: Fonts },
    ],
  },
];
