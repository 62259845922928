import { LinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { $getSelection, $isRangeSelection } from "lexical";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { IconButton } from "../../IconButton";
import styles from "./Toolbar.module.css";
import { LinkInput } from "./LinkInput";

export function LinkButton() {
  const [active, setActive] = useState<boolean>(false);
  const [showLinkInput, setShowLinkInput] = useState<boolean>(false);
  const [linkValue, setLinkValue] = useState<string | null>(null);
  const [editor] = useLexicalComposerContext();

  const updateButton = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();

      const nearestLink = $getNearestNodeOfType(anchorNode, LinkNode);
      nearestLink && setLinkValue(nearestLink.getURL());
      setActive(!!nearestLink);
    }
  }, []);

  const saveURL = async (url: string | null) => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
    closeLinkInput();
  };

  const closeLinkInput = async () => {
    setShowLinkInput(false);
    setLinkValue(null);
  };

  const onClick = async () => {
    setShowLinkInput(!showLinkInput);
  };
  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateButton();
          });
        }),
      ),
    [editor, updateButton],
  );

  return (
    <>
      {showLinkInput && <LinkInput value={linkValue} onSubmit={saveURL} onClose={closeLinkInput} />}
      <IconButton
        className={classNames(styles.button, { [styles.active]: active })}
        square
        onClick={onClick}
        iconType="Link"
      />
    </>
  );
}
