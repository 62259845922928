import { ThemePayload, getThemeName } from "schemas";
import {
  PropsWithClassNameAndChildren,
  SmallTextMain,
  ThemedWrapper,
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  paddingStyles,
  shadowStyles,
  transitionStyles,
  widthStyles,
} from "ui";
import classNames from "classnames";
import { useCurrentBusiness } from "../../store";

interface Props {
  theme: ThemePayload;
}
export function Preview({ theme }: Props) {
  const business = useCurrentBusiness();
  const name = getThemeName(theme);

  return (
    <ThemedWrapper
      className={classNames(
        flexStyles.vert050,
        flexStyles.alignCenter,
        paddingStyles.py125,
        paddingStyles.px075,
        shadowStyles.shadow30,
        transitionStyles.all200,
        widthStyles.width1000,
      )}
      theme={theme}
    >
      {business.logo && (
        <img
          src={`${business.logo}/logo`}
          width="32"
          height="32"
          className={classNames(borderStyles.radiusRound, borderStyles.businessBorderColor)}
          alt={`${business.businessName} logo`}
        />
      )}
      <SmallTextMain>{name}</SmallTextMain>
      <Box />
      <Box>
        <div className={flexStyles.horiz025}>
          <div
            className={classNames(
              borderStyles.tagBorderColorDashed,
              bgStyles.businessLight,
              widthStyles.width162,
              heightStyles.height075,
              borderStyles.radius037,
            )}
          />
          <div
            className={classNames(
              borderStyles.tagBorderColorDashed,
              bgStyles.businessLight,
              widthStyles.width162,
              heightStyles.height075,
              borderStyles.radius037,
            )}
          />
        </div>
        <Box className={bgStyles.businessButton} />
      </Box>
    </ThemedWrapper>
  );
}

function Box({ children, className }: PropsWithClassNameAndChildren) {
  return (
    <div
      style={{ minHeight: "1.25rem" }}
      className={classNames(flexStyles.vert050, widthStyles.full, bgStyles.neutral0, paddingStyles.p050, className)}
    >
      {children}
    </div>
  );
}
