import { useState } from "react";
import { Description, SubheaderMain, TextMain } from "ui";
import { SuperCard } from "../ui";

interface Props {
  label: string;
  stats: string[];
}

export function CampaignStat({ label, stats }: Props) {
  const [open, setOpen] = useState(false);
  const toggle = async () => {
    stats.length > 0 && setOpen(!open);
  };
  return (
    <SuperCard
      onClick={toggle}
      title={<TextMain>{label}</TextMain>}
      right={<SubheaderMain>{stats.length}</SubheaderMain>}
    >
      {open && stats.map((email) => <Description key={`${label}-${email}`}>{email}</Description>)}
    </SuperCard>
  );
}
