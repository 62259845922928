import { ReactNode } from "react";
import { useWatch } from "react-hook-form";
import { PropsWithName } from "ui";

interface Props extends PropsWithName {
  children: (count: number) => ReactNode;
  offset?: number;
}
export function ContactCounter({ name, children, offset = 0 }: Props) {
  const contacts = useWatch({ name });
  const count = contacts ? contacts.length : 0;
  return children(count - offset);
}
