import { useEffect, useState } from "react";
import { PersistedContactImport } from "schemas";
import { useLiteSiteStore } from "../store";
import { setContactImport } from "./setContactImport";
import { apiRequest } from "../apiRequest";
import { HookLoading } from "../types";
import { useIdParam } from "../../hooks";

async function getContactImport(id: number) {
  const contactImport = await apiRequest<PersistedContactImport>({ url: `contactImports/${id}` });
  setContactImport(contactImport);
}

export function useContactImport(): HookLoading<{ contactImport?: PersistedContactImport }> {
  const [loading, setLoading] = useState<boolean>(true);
  const id = useIdParam<number>("contactImportId");
  useEffect(() => {
    if (id) {
      getContactImport(id).then(() => setLoading(false));
    }
  }, [id]);
  const contactImport = useLiteSiteStore(({ csvContactImports }) => id && csvContactImports.get(+id));

  return { loading, contactImport: contactImport || undefined };
}
