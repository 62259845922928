import { EmptySignup, SignupPayload, SignupSchema } from "schemas";
import { FormProvider, FormTag, PasswordInput, ReCaptcha, SubheaderMain, TextInput } from "ui";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthScreen } from "./AuthScreen";
import { Or, Submit } from "../ui";
import { TheOtherMode } from "./TheOtherMode";
import { GoogleButton } from "../ui/GoogleButton";
import { signup } from "../../store";

export function Signup() {
  const navigate = useNavigate();

  const onSignup = async (payload: SignupPayload) => {
    try {
      const user = await signup({ payload });
      if (user) {
        // forces a reload
        window.location.href = "/";
      }
    } catch (error) {
      if ((error as Error).message === "link is not available") {
        toast.error("This link is no longer available. Please choose a different one.");
        navigate("/auth/signup");
      }
    }
  };

  return (
    <FormProvider<SignupPayload> defaultValues={EmptySignup} schema={SignupSchema}>
      <AuthScreen noTagline>
        <div>
          <SubheaderMain>Signup for LiteSite</SubheaderMain>
        </div>
        <FormTag<SignupPayload> onSubmit={onSignup}>
          <TextInput type="text" name="business.businessName" label="Business Name" placeholder="Jane's Drip Coffee" />
          <TextInput type="email" name="user.email" label="Email" placeholder="name@domain.com" />
          <PasswordInput name="user.password" label="Password" placeholder="*****" />
          <ReCaptcha action="signup" />
          <Submit label="Signup" disableClean />
        </FormTag>
        <Or />
        <GoogleButton type="login" label="Continue with Google" />
        <TheOtherMode destination="login" />
      </AuthScreen>
    </FormProvider>
  );
}
