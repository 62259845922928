import { PersistedImageBlock } from "schemas";
import { Card } from "../Card";
import classNames from "classnames";
import { displayStyles, imageStyles, overflowStyles, paddingStyles, pointerStyles, widthStyles } from "../styles";

interface Props {
  block: PersistedImageBlock;
  disabled: boolean;
}

export function ImageCard({ block, disabled }: Props) {
  const onClick = disabled || !block.url ? undefined : () => block.url && window.open(block.url, "_blank");
  return (
    <Card
      onClick={onClick}
      className={classNames(widthStyles.full, overflowStyles.hidden, paddingStyles.p0, {
        [pointerStyles.cursor]: block.url,
      })}
    >
      <picture>
        <source srcSet={`${block.image}/feed`} type="image/webp" />
        <img
          className={classNames(widthStyles.full, displayStyles.block, imageStyles.fitContain)}
          src={`${block.image}/feed`}
          alt={block.alt ? block.alt : undefined}
        />
      </picture>
    </Card>
  );
}
