import { PhoneBlockPayload, PhoneBlockSchema } from "schemas";
import { FormProvider, FormTag, PhoneInput, TextInput } from "ui";
import { Submit } from "../ui";

interface Props {
  initialValues: PhoneBlockPayload;
  onSubmit: (data: PhoneBlockPayload) => Promise<void>;
  cancelPath: string;
}

export function PhoneForm({ initialValues, onSubmit, cancelPath }: Props) {
  return (
    <FormProvider<PhoneBlockPayload> schema={PhoneBlockSchema} defaultValues={initialValues}>
      <FormTag<PhoneBlockPayload> onSubmit={onSubmit}>
        <TextInput autoFocus name="label" label="Button label" />
        <PhoneInput name="number" label="Phone number" />
        <Submit cancelTo={cancelPath} />
      </FormTag>
    </FormProvider>
  );
}
