import classNames from "classnames";
import { PropsWithChildren } from "react";
import { flexStyles, heightStyles, overflowStyles } from "ui";
import { Sidebar } from "./Sidebar";

export function DesktopNav({ children }: PropsWithChildren) {
  return (
    <div className={classNames(flexStyles.horiz, flexStyles.justifyStretch)}>
      <Sidebar />
      <div className={classNames(flexStyles.vert, flexStyles.grow, heightStyles.dvh100, overflowStyles.auto)}>
        {children}
      </div>
    </div>
  );
}
