import { Fragment } from "react";
import { SubheaderMain } from "ui";
import { PersistedContact } from "schemas";
import { NoResults } from "./NoResults";
import { ContactCard } from "./ContactCard";
import { SelectableContact } from "./SelectableContact";

interface Props {
  contacts: PersistedContact[];
  query?: string;
  name?: string;
  hideInitials?: boolean;
  disabledIds?: number[];
  newContactTo: string;
}
export function ContactsList({ contacts, query, name, hideInitials, newContactTo, disabledIds = [] }: Props) {
  const letters: string[] = [];

  if (contacts.length === 0) {
    return <NoResults newContactTo={newContactTo} query={query} />;
  }

  return contacts.map((contact) => {
    const { initial, id } = contact;
    let letterBlock;
    if (letters.indexOf(initial) < 0) {
      letters.push(initial);
      letterBlock = <SubheaderMain>{initial}</SubheaderMain>;
    }
    return (
      <Fragment key={id}>
        {!hideInitials && letterBlock}
        {name ? (
          <SelectableContact disabled={disabledIds.includes(id)} contact={contact} name={name} />
        ) : (
          <ContactCard contact={contact} />
        )}
      </Fragment>
    );
  });
}
