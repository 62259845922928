import { EditableBlockKinds } from "schemas";
import { EmailForm } from "./EmailForm";
import { PhoneForm } from "./PhoneForm";
import { LinkForm } from "./LinkForm";
import { AppointmentLinkForm } from "./AppointmentLinkForm";
import { SocialLinksForm } from "./SocialLinksForm";
import { FC } from "react";
import { FormLinkForm } from "./FormLinkForm";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BlockFormComponents: Record<EditableBlockKinds, FC<any>> = {
  AppointmentLinkBlock: AppointmentLinkForm,
  EmailBlock: EmailForm,
  FormLinkBlock: FormLinkForm,
  LinkBlock: LinkForm,
  PhoneBlock: PhoneForm,
  SocialLinksBlock: SocialLinksForm,
};
