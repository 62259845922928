import { useNavigate } from "react-router-dom";
import { Modal } from "ui";
import { useEffect } from "react";
import { SuccessCard } from "../ui/SuccessCard";
import { useCustomerPaymentsRedirect } from "../../hooks";

export function Success() {
  const navigate = useNavigate();
  const { customerPaymentsRedirect, removeCustomerPaymentsRedirect } = useCustomerPaymentsRedirect();
  const successTo = customerPaymentsRedirect || "/content";

  useEffect(() => {
    removeCustomerPaymentsRedirect();
  }, [removeCustomerPaymentsRedirect]);

  return (
    <Modal header="LiteSite Business" onClose={() => navigate("/more")}>
      <SuccessCard
        header="Congratulations you can now accept payments"
        subheader="Configure your appointments now!"
        successTo={successTo}
      />
    </Modal>
  );
}
