"use client";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { $createParagraphNode, $createTextNode, $getRoot, EditorState } from "lexical";
import classNames from "classnames";
import { useWatch } from "react-hook-form";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { $rootTextContent } from "@lexical/text";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { borderStyles, pointerStyles, positionStyles, textStyles } from "../../styles";
import { SubmitPlugin } from "./SubmitPlugin";
import { useState } from "react";

export type PlainTextEditorSize = "SubHeader" | "Header" | "Text";

interface Props {
  name: string;
  setValue: (value: string, shouldValidate: boolean) => void;
  placeholder: string;
  size: PlainTextEditorSize;
  onSubmit: () => Promise<void>;
}

export function PlainText({ name, setValue, placeholder, size, onSubmit }: Props) {
  const value = useWatch({ name });
  const [beenInit, setBeenInit] = useState(false);

  const initialConfig = {
    theme: {},
    namespace: `${name}-simple-editor`,
    nodes: [],
    onError: (error: Error) => {
      console.error(error);
    },
    editorState: () => {
      $getRoot().append($createParagraphNode().append($createTextNode(value)));
    },
  };
  const onChange = (editorState: EditorState) => {
    editorState.read(() => {
      setValue($rootTextContent(), beenInit);
      !beenInit && setBeenInit(true);
    });
  };

  return (
    <div className={classNames(positionStyles.relative)}>
      <LexicalComposer initialConfig={initialConfig}>
        <PlainTextPlugin
          contentEditable={
            <ContentEditable
              className={classNames(textStyles.lsColor, positionStyles.relative, borderStyles.outlineEditorBorder, {
                [textStyles.size125]: size === "SubHeader",
                [textStyles.weight600]: size === "SubHeader",
                [textStyles.size175]: size === "Header",
                [textStyles.weight500]: size === "Header",
                [textStyles.size100]: size === "Text",
              })}
            />
          }
          placeholder={
            <div
              className={classNames(
                positionStyles.absolute,
                positionStyles.left006,
                positionStyles.right006,
                positionStyles.top0,
                textStyles.lsColor,
                textStyles.editorBorder,
                pointerStyles.eventsNone,
                {
                  [textStyles.size125]: size === "SubHeader",
                  [textStyles.weight600]: size === "SubHeader",
                  [textStyles.size100]: size === "Text",
                  [textStyles.size175]: size === "Header",
                  [textStyles.weight500]: size === "Header",
                },
              )}
            >
              {placeholder}
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <OnChangePlugin onChange={onChange} />
        <SubmitPlugin onSubmit={onSubmit} />
        <HistoryPlugin />
        <AutoFocusPlugin />
      </LexicalComposer>
    </div>
  );
}
