"use client";

import { useLongPress } from "react-use";
import { PropsWithChildren, ReactNode, useState } from "react";
import classNames from "classnames";
import { OnClickHandler } from "../types";
import styles from "./Button.module.css";
import { borderStyles, flexStyles, paddingStyles, pointerStyles, positionStyles, textStyles } from "../styles";

interface Props extends PropsWithChildren {
  onPressed: OnClickHandler;
  pressedContent: ReactNode;
  disabled?: boolean;
}

export function HoldButton({ children, onPressed, pressedContent, disabled = false }: Props) {
  const [pressed, setPressed] = useState<boolean>(false);
  const onPressDone = () => {
    if (!pressed) {
      setPressed(true);
      onPressed();
    }
  };

  const longPress = useLongPress(onPressDone, { delay: 2500 });

  return (
    <button
      disabled={disabled || pressed}
      {...longPress}
      className={classNames(
        flexStyles.horiz050,
        flexStyles.alignCenter,
        flexStyles.justifyCenter,
        pointerStyles.cursor,
        positionStyles.relative,
        textStyles.uppercase,
        textStyles.decorationNone,
        textStyles.size100,
        textStyles.weight600,
        borderStyles.radius025,
        paddingStyles.py050,
        paddingStyles.px125,
        styles.btn,
        styles.mHold,
        {
          [styles.mPressed]: pressed,
        },
      )}
    >
      {pressed ? pressedContent : children}
    </button>
  );
}
