import { ContactPayload, ContactWithGroupsPayload, ContactWithGroupsSchema } from "schemas";
import {
  FormProvider,
  FormTag,
  Loader,
  PhoneInput,
  SearchItem,
  TagInput,
  TextArea,
  TextInput,
  TextMain,
  heightStyles,
} from "ui";
import { Submit } from "../ui";
import { ExceededLimit } from "./ExceededLimit";
import { useContactGroups } from "../../store";

interface Props {
  values: ContactPayload;
  onSubmit: (values: ContactWithGroupsPayload) => Promise<void>;
  overLimit?: boolean;
}
export function Form({ values, onSubmit, overLimit }: Props) {
  const { loading, contactGroups } = useContactGroups();
  const nonAutoGroups = contactGroups.filter((contactGroup) => !contactGroup.autogeneratedType);

  if (loading) {
    return <Loader />;
  }

  // @ts-expect-error id thing
  const id: number | undefined = values.id;

  const contactGroupsForContact = id
    ? nonAutoGroups.filter((group) => group.contacts.includes(id)).map((group) => group.id)
    : // @ts-expect-error bloody contact groups
      values.contactGroups || [];

  const groups: SearchItem[] = nonAutoGroups.map((group) => ({
    id: group.id,
    label: group.name,
    searchable: [group.name.toLowerCase()],
  }));

  return (
    <FormProvider<ContactWithGroupsPayload>
      defaultValues={{ ...values, contactGroups: contactGroupsForContact }}
      schema={ContactWithGroupsSchema}
    >
      <FormTag<ContactWithGroupsPayload> onSubmit={onSubmit}>
        <TextMain>Fill in the contact&apos;s details</TextMain>
        <TextInput label="Name" name="name" placeholder="Type in text" />
        <TextInput label="Email" name="email" type="email" placeholder="mail@mail.com" />
        <TagInput label="Groups" name="contactGroups" collection={groups} />
        <TextArea className={heightStyles.height1000} label="Note" name="note" placeholder="Add a note here" />
        <PhoneInput name="phone" label="Phone" />
        {overLimit && <ExceededLimit />}
        <Submit disableInvalid disabled={overLimit} cancelTo=".." />
      </FormTag>
    </FormProvider>
  );
}
