import { TextMain, textStyles } from "ui";
import { Link } from "../ui";
import { useUrlWithBack } from "../../hooks";

type MessageType = "contacts" | "campaign";

interface Props {
  type?: MessageType;
}

const copy: Record<MessageType, string> = {
  contacts: "This import will lead you to exceed the maximum free contacts count.",
  campaign: "You have exceeded the free contact allowance, please upgrade to send this campaign.",
};

export function ExceededLimit({ type = "contacts" }: Props) {
  const upgradePath = useUrlWithBack("/billing/discovered/contacts");

  return (
    <TextMain className={textStyles.errorDark}>
      {copy[type]} <Link to={upgradePath}>Upgrade to LiteSite Business</Link>
    </TextMain>
  );
}
