import { SVGProps } from "react";

const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={75} height={16} viewBox="0 0 75 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#Logo_svg__a)" fill="#191B20">
      <path d="M3.685 9.093V.283H0v9.213c0 1.65.658 3.233 1.829 4.4a6.249 6.249 0 0 0 4.412 1.822h3.37V12.74H7.34a3.663 3.663 0 0 1-2.584-1.069 3.643 3.643 0 0 1-1.07-2.578ZM14.742.282h-3.62V3.13h3.62V.282ZM14.676 4.479H11.19v11.237h3.487V4.479ZM19.662 12.085V6.978h2.833v-2.5h-2.833V.284h-3.486v12.042c0 2.412 1.305 3.675 4.162 3.675.743 0 1.484-.095 2.202-.285v-2.584a4.97 4.97 0 0 1-1.354.216c-1.088 0-1.524-.368-1.524-1.262ZM29.452 4.197c-3.616 0-5.95 2.325-5.95 5.782v.217c0 3.522 2.269 5.807 5.95 5.807 3.008 0 5.036-1.523 5.581-3.894h-3.422c-.24.719-.937 1.327-2.18 1.327-1.503 0-2.31-.826-2.463-2.543h8.13c.239-3.959-1.722-6.696-5.646-6.696Zm-2.44 4.673c.24-1.456 1.004-2.108 2.4-2.108s2.092.827 2.179 2.108h-4.578ZM43.892 6.39c-2.944-.477-3.378-.976-3.378-1.868v-.067c0-.911.74-1.603 2.311-1.603 1.608 0 2.591.694 2.744 2.15h3.64c-.26-3.24-2.703-5-6.32-5-3.488 0-6.016 1.784-6.016 4.608v.152c0 2.543 1.59 3.935 5.317 4.609 2.92.522 3.619.979 3.619 2.022v.086c0 1.045-.958 1.655-2.594 1.655-1.83 0-3.007-.83-3.225-2.458h-3.64c.218 3.326 2.682 5.327 6.78 5.327 3.681 0 6.34-1.849 6.34-4.848v-.175c-.002-2.676-2.03-4.003-5.578-4.59ZM54.621.282h-3.619V3.13h3.62V.282ZM54.556 4.479h-3.488v11.237h3.488V4.479ZM59.543 12.085V6.978h2.831v-2.5h-2.831V.284h-3.488v12.042c0 2.412 1.307 3.675 4.162 3.675a8.573 8.573 0 0 0 2.202-.285v-2.584a4.953 4.953 0 0 1-1.352.216c-1.09 0-1.524-.368-1.524-1.262ZM69.313 13.432c-1.503 0-2.312-.827-2.465-2.544h8.13c.24-3.956-1.718-6.695-5.644-6.695-3.618 0-5.951 2.325-5.951 5.782v.217c0 3.521 2.268 5.806 5.951 5.806 3.008 0 5.034-1.522 5.58-3.893H71.49c-.24.722-.938 1.327-2.178 1.327Zm-.022-6.673c1.396 0 2.092.826 2.18 2.108h-4.578c.241-1.454 1.004-2.106 2.398-2.106v-.002Z" />
    </g>
    <defs>
      <clipPath id="Logo_svg__a">
        <path fill="#fff" d="M0 0h75v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLogo;
