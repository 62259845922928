import { PersistedContact } from "schemas";
import { setContact } from "./setContact";
import { apiRequest } from "../apiRequest";

export async function unsubscribeContact(id: number) {
  const contact = await apiRequest<PersistedContact>({
    method: "PATCH",
    url: `contacts/${id}/unsubscribe`,
  });

  setContact(contact);
}
