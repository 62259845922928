import { BlockKind, BlockPayload, PersistedBlock } from "schemas";
import { setBlock } from "./setBlock";
import { apiRequest } from "../apiRequest";

interface Args {
  id: string;
  kind: BlockKind;
  payload: BlockPayload;
}
export async function updateBlock({ id, kind, payload }: Args) {
  const block = await apiRequest<PersistedBlock>({
    url: `blocks/${kind}/${id}`,
    method: "PATCH",
    body: JSON.stringify(payload),
  });
  setBlock(block);
}
