import { SelectContactsPayload } from "schemas";
import { apiRequest } from "../apiRequest";
import { getContactGroup } from "../contactGroups/useContactGroup";

interface Args {
  contactGroupId: number;
  payload: SelectContactsPayload;
}
export async function removeContactGroupMembershps({ contactGroupId, payload }: Args) {
  await apiRequest({
    url: `contactGroupMemberships/${contactGroupId}`,
    method: "DELETE",
    body: JSON.stringify(payload),
  });
  await getContactGroup(contactGroupId);
}
