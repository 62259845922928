import {
  DescriptionAccent,
  TextMain,
  ThemedWrapper,
  bgStyles,
  borderStyles,
  flexStyles,
  marginStyles,
  paddingStyles,
  pointerStyles,
  squareStyles,
  textStyles,
  widthStyles,
} from "ui";
import { PersistedTheme, getThemeName } from "schemas";
import classNames from "classnames";
import { Link } from "./Link";

interface Props {
  theme: PersistedTheme;
}
export function Theme({ theme }: Props) {
  const label = getThemeName(theme);

  return (
    <Link
      className={classNames(widthStyles.full, textStyles.neutral600, bgStyles.neutral0, textStyles.decorationNone)}
      to="design"
    >
      <div
        className={classNames(
          flexStyles.horizApart,
          paddingStyles.p125,
          widthStyles.maxWidth4225,
          marginStyles.centered,
          textStyles.fontPoppins,
          pointerStyles.cursor,
        )}
      >
        <div className={flexStyles.vert}>
          <DescriptionAccent>Theme</DescriptionAccent>
          <TextMain>{label}</TextMain>
        </div>
        <ThemedWrapper className={classNames(squareStyles.square225, borderStyles.radius025)} theme={theme} />
      </div>
    </Link>
  );
}
