import { Asserts, boolean, mixed, object } from "yup";
import { HexColor } from "./shared";
import {
  BuiltInGradients,
  BuiltInGradientsValues,
  ThemeType,
  ThemeTypeValues,
  ThemeVariation,
  ThemeVariationValues,
} from "../enums";
import { FontFamily, FontFamilyValues } from "../themes";

export const ThemeSchema = object({
  type: mixed<ThemeType>().required().oneOf(ThemeTypeValues),
  primaryColor: HexColor,
  gradient: mixed<BuiltInGradients>().required().oneOf(BuiltInGradientsValues),
  variation: mixed<ThemeVariation>().required().oneOf(ThemeVariationValues),
  fontFamily: mixed<FontFamily>().required().oneOf(FontFamilyValues),
  defaultCampaignTheme: boolean(),
}).noUnknown();

type BasePayload = Asserts<typeof ThemeSchema>;
type SolidPayload = BasePayload & {
  type: "SolidColor";
};
type GradientPayload = BasePayload & {
  type: "Gradient";
  gradient: BuiltInGradients;
};
export type ThemePayload = SolidPayload | GradientPayload;

export type PersistedTheme = ThemePayload & {
  id: string;
  defaultCampaignTheme: boolean;
};

export const DefaultTheme: ThemePayload = {
  type: "SolidColor",
  gradient: "SeaGreen",
  primaryColor: "#BFC0BC",
  variation: "Primary",
  fontFamily: "Poppins",
};
