import classNames from "classnames";
import { ChangeEvent, useState } from "react";
import { bgStyles, flexStyles, paddingStyles, positionStyles } from "../../styles";
import { IconButton } from "../../IconButton";
import { SmallTextInput } from "../TextInput/SmallTextInput";
import { withHttp } from "../../../helpers";

interface Props {
  onClose: () => Promise<void>;
  onSubmit: (value: string | null) => Promise<void>;
  value: string | null;
}
export function LinkInput({ onClose, onSubmit, value }: Props) {
  const [candidate, setCandidate] = useState<string | null>(value);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCandidate(withHttp(e.target.value));
  };

  const localSubmit = async () => {
    onSubmit(candidate);
  };

  return (
    <div
      className={classNames(
        flexStyles.horiz100,
        flexStyles.alignCenter,
        bgStyles.neutral200,
        positionStyles.absolute,
        positionStyles.top0,
        positionStyles.left0,
        positionStyles.right0,
        positionStyles.bottom0,
        positionStyles.zIndex5,
        paddingStyles.py025,
        paddingStyles.px050,
      )}
    >
      <IconButton iconType="Delete" onClick={() => onSubmit(null)} />
      <SmallTextInput
        value={candidate}
        placeholder="https://..."
        className={flexStyles.grow}
        type="url"
        name="url"
        onChange={onChange}
      />
      <IconButton iconType="Check" onClick={localSubmit} />
      <IconButton iconType="X" onClick={onClose} />
    </div>
  );
}
