import { BlockPayload, InlineEditableBlockKindValues, InlineEditableBlockKinds } from "schemas";
import { FC } from "react";
import { TextEditor } from "./TextEditor";
import { ImageEditor } from "./ImageEditor";
import { VideoLinkEditor } from "./VideoLinkEditor";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Components: Record<InlineEditableBlockKinds, FC<any>> = {
  TextBlock: TextEditor,
  ImageBlock: ImageEditor,
  VideoLinkBlock: VideoLinkEditor,
  VideoBlock: VideoLinkEditor,
};
interface Props {
  block: BlockPayload;
  onSubmit: (payload: BlockPayload) => Promise<void>;
  onDelete?: () => Promise<void>;
  onCancel: () => void;
}
export function InlineEditor({ block, onSubmit, onDelete, onCancel }: Props) {
  if (!InlineEditableBlockKindValues.includes(block.kind)) {
    return null;
  }

  const Editor = Components[block.kind as InlineEditableBlockKinds];

  return <Editor block={block} onSubmit={onSubmit} onCancel={onCancel} onDelete={onDelete} />;
}
