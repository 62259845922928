import { PersistedContact } from "schemas";
import { setContact } from "./setContact";
import { useLiteSiteStore } from "../store";
import { useEffect } from "react";
import { apiRequest } from "../apiRequest";
import { useIdParam } from "../../hooks";

async function getContact(id: number) {
  const contact = await apiRequest<PersistedContact>({ url: `contacts/${id}` });
  setContact(contact);
}

export function useContact(passedId?: number) {
  const urlId = useIdParam<number>("contactId");
  const id = passedId || urlId;
  const contact = useLiteSiteStore((state) => state.contacts.get(Number(id)));
  useEffect(() => {
    if (id) {
      getContact(id);
    }
  }, [id]);

  return contact;
}
