import { Drawer, Loader, flexStyles } from "ui";
import { useContactImport } from "../../store";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { CandidateCard } from "./CandidateCard";

export function DuplicatesDrawer() {
  const navigate = useNavigate();
  const { contactImport } = useContactImport();
  if (!contactImport) {
    return <Loader />;
  }

  const duplicates = contactImport.contacts.filter((contact) => contact.duplicate);

  const onClose = () => navigate("..");

  return (
    <Drawer title="Contact duplicates" open onClose={onClose} className={classNames(flexStyles.vert125)}>
      {duplicates.map((contact) => (
        <CandidateCard key={`candidate-${contact.email}`} contact={contact} />
      ))}
    </Drawer>
  );
}
