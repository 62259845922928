import {
  RawIcon,
  SmallTextAccent,
  SmallTextMain,
  bgStyles,
  flexStyles,
  paddingStyles,
  positionStyles,
  textStyles,
} from "ui";
import classNames from "classnames";
import { Button } from "./Button";
import { useUrlWithBack } from "../../hooks";
import { useOnBusinessPlan } from "../../store";

export function UpgradeBox() {
  const onBusinessPlan = useOnBusinessPlan();
  const upgradeLink = useUrlWithBack("/billing/upgrade");

  if (onBusinessPlan) {
    return null;
  }

  return (
    <div
      className={classNames(
        flexStyles.vert125,
        flexStyles.alignCenter,
        bgStyles.blueUpgrade,
        textStyles.center,
        textStyles.neutral0,
        paddingStyles.p100,
        positionStyles.relative,
      )}
    >
      <RawIcon
        iconType="UpdateTwirl"
        className={classNames(positionStyles.absolute, positionStyles.left0, positionStyles.top0)}
      />
      <RawIcon
        iconType="UpdateTwirl2"
        className={classNames(positionStyles.absolute, positionStyles.right0, positionStyles.bottom0)}
      />
      <div className={flexStyles.vert050}>
        <SmallTextAccent>Make more with LiteSite Business!</SmallTextAccent>
        <SmallTextMain>Payment up-front, custom branding, SMS automations and much more!</SmallTextMain>
      </div>
      <Button buttonType="Secondary" to={upgradeLink}>
        Learn More
      </Button>
    </div>
  );
}
