import { PersistedPage } from "schemas";
import classNames from "classnames";
import { HeaderMain, Markdown, PopoverLinkItem, PropsWithOnClick, flexStyles, textStyles, widthStyles } from "ui";
import { EditWrapper } from "../../blocks";

interface Props extends PropsWithOnClick {
  page: PersistedPage;
  open: boolean;
  links?: PopoverLinkItem[];
}
export function PageDetails({ page, open, onClick, links = [] }: Props) {
  return (
    <EditWrapper
      className={classNames(textStyles.left, flexStyles.gap100, flexStyles.alignStart)}
      links={links}
      open={open}
      onClick={onClick}
    >
      <HeaderMain>{page.name}</HeaderMain>
      {page.description && <Markdown className={widthStyles.full}>{page.description}</Markdown>}
    </EditWrapper>
  );
}
