import { BlockDestination, BlockKind, BlockPayload, TextBlockPayload, emptyBlock } from "schemas";
import { InlineEditor } from "./InlineEditor";
import { addBlock } from "../../../store";
import { EditWrapper } from "../../blocks";
import { useNavigate } from "react-router-dom";

interface Props {
  destination: BlockDestination;
  destinationIndex: number;
  kind: BlockKind | "HeadingBlock";
}
export function NewInlineBlock({ destination, destinationIndex, kind }: Props) {
  const navigate = useNavigate();

  const onSubmit = async (payload: BlockPayload) => {
    await addBlock({ destinationIndex: destinationIndex!, destination, payload });
    navigate(".");
  };

  const newBlock =
    kind === "HeadingBlock" ? { ...emptyBlock("TextBlock"), text: "# " } : (emptyBlock(kind) as TextBlockPayload);

  const editor = <InlineEditor block={newBlock} onSubmit={onSubmit} onCancel={() => navigate(".")} />;
  return <EditWrapper open editElement={editor} />;
}
