import { useEffect } from "react";
import { useLiteSiteStore } from "../store";
import { PersistedContact } from "schemas";
import { setContact } from "./setContact";
import { apiRequest } from "../apiRequest";

async function getContacts() {
  const contacts = await apiRequest<PersistedContact[]>({ url: "contacts" });
  contacts.map(setContact);
}

function sortName(contact: PersistedContact) {
  return (contact.name || contact.email).toLowerCase();
}

export function useContacts() {
  useEffect(() => {
    getContacts();
  }, []);

  return useLiteSiteStore((state) =>
    [...state.contacts.values()].sort((a, b) => sortName(a).localeCompare(sortName(b))),
  );
}
