import { Icon, SubheaderMain, TextMain, flexStyles, iconStyles, paddingStyles, widthStyles } from "ui";
import classNames from "classnames";
import { Button } from "../ui";
import { useCampaign } from "../../store";

export function Sent() {
  const { campaign } = useCampaign();

  return (
    <div
      className={classNames(
        iconStyles.successMedium,
        flexStyles.grow,
        flexStyles.vert100,
        flexStyles.justifyBetween,
        flexStyles.alignCenter,
        paddingStyles.px125,
        paddingStyles.pb200,
        paddingStyles.pt600,
      )}
    >
      <div className={classNames(flexStyles.vert050, flexStyles.alignCenter)}>
        <Icon iconType="Success" />
        <div className={classNames(flexStyles.vert125, flexStyles.alignCenter)}>
          <SubheaderMain>Campaign sent successfully!</SubheaderMain>
          <TextMain>Campaign &quot;{campaign?.name}&quot; sent successfully.</TextMain>
        </div>
      </div>
      <Button className={classNames(widthStyles.maxContent, widthStyles.full)} buttonType="Secondary" to="/campaigns">
        Done
      </Button>
    </div>
  );
}
