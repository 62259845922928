import { PopoverLinkItem, PopoverMenu } from "ui";
import { useFormContext } from "react-hook-form";

interface Props<T> {
  onSubmit: (payload: T) => Promise<void>;
  onDelete?: () => Promise<void>;
  onCancel: () => void;
  extraLinks?: PopoverLinkItem[];
}
export function Menu<T extends object>({ onSubmit, onDelete, onCancel, extraLinks = [] }: Props<T>) {
  const {
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useFormContext<T>();

  const links: PopoverLinkItem[] = [
    {
      label: "Cancel",
      onClick: async () => {
        onCancel();
      },
      iconType: "X",
    },
  ];

  if (onDelete) {
    links.push({
      label: "Delete",
      onClick: onDelete,
      iconType: "Delete",
    });
  }

  if (isDirty) {
    links.unshift({
      label: "Save",
      onClick: handleSubmit(onSubmit),
      iconType: "Check",
      loading: isSubmitting || undefined,
    });
  }

  const maxLinks = Math.min(3, links.length);

  const linksWithExtra = links.concat(extraLinks);

  return <PopoverMenu maxLinks={maxLinks} open links={linksWithExtra} />;
}
