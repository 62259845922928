import ReactMarkdown from "react-markdown";
// import Markdown from 'markdown-to-jsx'
import classNames from "classnames";
import { PropsWithClassNameAndChildren } from "../types";
import styles from "./Markdown.module.css";
import { PropsWithChildren } from "react";
import { textStyles } from "../styles";

type MarkdownSize = "small" | "regular";
interface Props extends PropsWithClassNameAndChildren {
  size?: MarkdownSize;
}

export function Markdown({ children, className, size = "regular" }: Props) {
  const components = {
    a(props: PropsWithChildren) {
      return (
        <a {...props} target="_blank">
          {props.children}
        </a>
      );
    },
  };

  return (
    <div dir="auto">
      <ReactMarkdown
        components={components}
        className={classNames(
          textStyles.lsColor,
          textStyles.weight600Strong,
          styles.wrapper,
          { [styles.small]: size === "small" },
          className,
        )}
      >
        {children as string}
      </ReactMarkdown>
    </div>
  );
}
