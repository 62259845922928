import { PersistedContactImport } from "schemas";
import { DetailsCard } from "./DetailsCard";
import { pluralize } from "../../helpers";
import { Outlet, useNavigate } from "react-router-dom";

interface Props {
  contactImport: PersistedContactImport;
}
export function Details({ contactImport }: Props) {
  const navigate = useNavigate();
  const { contacts } = contactImport;

  const duplicates = contacts.filter((contact) => contact.duplicate);

  if (contactImport.type === "csv" && !contactImport.userUpload) {
    return null;
  }

  return (
    <>
      <Outlet />
      <DetailsCard
        label="Found for import"
        text={`${contacts.length} ${pluralize("contact", contacts.length)}`}
        onClick={() => navigate("candidates")}
      />
      {duplicates.length > 0 && (
        <DetailsCard
          label="Duplicates"
          text={`${duplicates.length} ${pluralize("contact", duplicates.length)}`}
          onClick={() => navigate("duplicates")}
        />
      )}
    </>
  );
}
