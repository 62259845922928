import { LinkInput } from "ui";
import { checkEntityLink } from "../../store";

const { REACT_APP_PUBLIC_DOMAIN } = import.meta.env;

interface Props {
  noSlug?: boolean;
}
export function BusinessLinkInput({ noSlug = false }: Props) {
  const checkLink = async (link: string, abortController: AbortController) => {
    const available = await checkEntityLink({ type: "business", link }, abortController);
    return available ? "" : `${link} is already taken`;
  };

  return (
    <LinkInput
      name="link"
      label="Your litesite link"
      base={`${REACT_APP_PUBLIC_DOMAIN}/`}
      check={checkLink}
      slugFrom={noSlug ? null : "businessName"}
    />
  );
}
