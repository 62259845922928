import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";

export async function deleteContactGroup(id: number) {
  await apiRequest({ url: `contactGroups/${id}`, method: "DELETE" });

  useLiteSiteStore.setState(({ contactGroups }) => {
    contactGroups.delete(id);
    return { contactGroups };
  });
}
