import classNames from "classnames";
import { PropsWithChildren } from "react";
import { CheckboxItem, flexStyles, opacityStyles } from "ui";
import { useToggle } from "./useToggle";
import { SuperCard } from "../SuperCard";

interface Props extends PropsWithChildren {
  id: number;
  name: string;
  disabled?: boolean;
}

export function Selectable({ id, name, children, disabled }: Props) {
  const { toggle } = useToggle({ name, id });

  return (
    <SuperCard
      className={classNames({
        [opacityStyles.transparentDisabled]: disabled,
      })}
      onClick={toggle}
      left={<CheckboxItem name={name} id={id} />}
      title={<div className={flexStyles.grow}>{children}</div>}
    />
  );
}
