"use client";

import { ChangeEvent, ComponentType, PropsWithChildren, useState } from "react";

import { useFormContext, useWatch } from "react-hook-form";
import { UploadPayload } from "schemas";
import classNames from "classnames";
import { Label } from "./Label";
import { Error } from "./Error";
import { Cropper, CropperAspect } from "../Cropper";
import { FileDrop, FileInput } from "./upload";
import { PropsWithClassName } from "../types";
import { borderStyles, displayStyles, flexStyles, positionStyles, squareStyles, widthStyles } from "../styles";

interface Props extends PropsWithClassName {
  name: string;
  label?: string;
  cropAspect?: CropperAspect;
  uploadImage?: (file: File) => Promise<UploadPayload>;
  disabled?: boolean;
  urlValue?: boolean;
  removeFeed?: boolean;
  prompt?: string;
  LabelComponent?: ComponentType<PropsWithChildren>;
}

const readFile = async (file: Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

export function ImageInput({
  cropAspect,
  name,
  label,
  uploadImage,
  LabelComponent,
  prompt = "UPLOAD IMAGE",
  disabled = false,
  urlValue = false,
  removeFeed = false,
  className,
}: Props) {
  const { register, setValue, clearErrors, setError } = useFormContext();
  const value = useWatch({ name });
  const initialValue = urlValue ? value : undefined;
  const [fileUrl, setFileUrl] = useState<string>(initialValue);
  const [uploading, setUploading] = useState<boolean>(false);
  const [cropFile, setCropFile] = useState<string>();
  register(name);

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) {
      return;
    }

    const file = e.target.files[0];
    if (cropAspect) {
      setError(name, { message: " " });
      readFile(file).then((result) => setCropFile(result as string));
    } else {
      onCropCompleted(file);
    }
  };

  const cancelCropping = () => {
    setCropFile(undefined);
  };

  const onCropCompleted = async (blob: Blob) => {
    if (!uploadImage) {
      return;
    }
    setUploading(true);
    clearErrors(name);
    try {
      const {
        key,
        bucket,
        fileUrl: uploadedFile,
      } = await uploadImage(new File([blob], "image.webp", { type: "image/webp" }));
      setCropFile(undefined);
      setFileUrl(uploadedFile);
      const newValue = urlValue ? uploadedFile : { key, bucket };
      setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error as { response: { data: { message: string } } };
      if (message) {
        setError(name, { message });
      }
    } finally {
      setUploading(false);
    }
  };
  const showImage = !cropFile && !uploading;
  const isRound = cropAspect === "Round";

  return (
    <div className={classNames(flexStyles.vert025, flexStyles.alignStretch, flexStyles.justifyStretch, className)}>
      {label && LabelComponent ? <LabelComponent>{label}</LabelComponent> : <Label name={name}>{label}</Label>}
      <FileDrop prompt={prompt} name={name} uploading={uploading} round={!cropFile && isRound}>
        {value && showImage && (
          <div
            className={classNames(positionStyles.relative, {
              [squareStyles.square1000]: isRound,
              [borderStyles.radiusRound]: isRound,
              [widthStyles.full]: !isRound,
            })}
          >
            <img
              className={classNames(displayStyles.block, widthStyles.full, {
                [squareStyles.square1000]: isRound,
                [borderStyles.radiusRound]: isRound,
              })}
              src={`${fileUrl}${removeFeed ? "" : "/feed"}`}
              alt=""
            />
          </div>
        )}
        {!uploading && cropFile && cropAspect && (
          <Cropper aspect={cropAspect} image={cropFile} onCancel={cancelCropping} onSubmit={onCropCompleted} />
        )}
        <FileInput type="image" disabled={disabled} onSelectFile={onSelectFile} />
      </FileDrop>
      <Error name={name} />
    </div>
  );
}
