import { PersistedContact } from "schemas";
import { apiRequest } from "../apiRequest";
import { setContact } from "../contacts/setContact";

export async function runContactImport(id: number): Promise<PersistedContact[]> {
  const contacts = await apiRequest<PersistedContact[]>({ url: `contactImports/${id}/run`, method: "PUT" });
  contacts.map(setContact);

  return contacts;
}
