import mixpanel from "mixpanel-browser";
import { PersistedBusiness, PersistedUser } from "schemas";

const { REACT_APP_MIXPANEL_TOKEN: MIXPANEL_TOKEN } = import.meta.env;

export async function identify(business: PersistedBusiness, user: PersistedUser) {
  if (MIXPANEL_TOKEN) {
    mixpanel.identify(business._id);
    mixpanel.people.set({
      name: user.name,
      email: user.email,
      businessName: business.businessName,
      litesiteUrl: business.url,
    });
  }
}

export async function track(eventName: string) {
  if (MIXPANEL_TOKEN) {
    mixpanel.track(eventName, {});
  }
}
