import {
  DotMenuLink,
  InfoCard,
  Loader,
  ResponsiveWrapper,
  TextAccent,
  TextMain,
  flexStyles,
  paddingStyles,
  useConfirm,
} from "ui";
import { Outlet, useNavigate } from "react-router-dom";
import { ContactsList } from "../contacts/ContactsList";
import { filterContacts } from "../../helpers/filterContacts";
import { deleteContactGroup, useContactGroup, useContacts } from "../../store";
import classNames from "classnames";
import { Panel } from "./Panel";
import { ConfirmCallout } from "../contacts";
import { Overlay } from "../ui";

export function Show() {
  const navigate = useNavigate();
  const contactGroup = useContactGroup();
  const contacts = useContacts();
  const { confirm, Confirm } = useConfirm();

  if (!contactGroup) {
    return <Loader />;
  }

  const links: DotMenuLink[] = [
    {
      label: "Edit",
      iconType: "Edit",
      onClick: () => navigate(`/contactGroups/${contactGroup.id}/edit`),
    },
    {
      label: "Delete",
      iconType: "Delete",
      onClick: () => {
        confirm({
          text: `Deleting the “${contactGroup.name}” group will remove it from your contact groups. The contacts within the group will remain unaffected and can still be accessed individually. Are you sure you want to delete this group?`,
          label: "Delete Group",
          animationType: "Thrash",
          onConfirm: async () => deleteContactGroup(contactGroup.id),
        });
      },
    },
  ];

  const filteredContacts = filterContacts(contacts, contactGroup);
  const hasPendingContacts = !!filteredContacts.find((contact) => contact.pending);

  return (
    <Overlay
      full
      header={contactGroup.name}
      backTo={{ to: "/contacts", label: "Contacts" }}
      links={!contactGroup.autogeneratedType ? links : undefined}
    >
      <Confirm />
      {contactGroup.autogeneratedType === "Unconfirmed" && <ConfirmCallout contacts={filteredContacts} />}
      <ResponsiveWrapper className={classNames(flexStyles.vert050, flexStyles.grow, paddingStyles.p125)}>
        <Panel count={filteredContacts.length} />
        {hasPendingContacts && (
          <InfoCard>
            <TextAccent>Some contacts are pending import verification.</TextAccent>
            <TextMain>
              Your imported contacts are awaiting approval from a Litesite admin. You can send emails to these contacts
              after approval.
            </TextMain>
          </InfoCard>
        )}
        <ContactsList newContactTo="contacts/new" contacts={filteredContacts} />
      </ResponsiveWrapper>
      <Outlet />
    </Overlay>
  );
}
