import {
  CheckboxWithLabel,
  Divider,
  ImageInput,
  LinkInput,
  LocationOptions,
  Radio,
  Select,
  TextInput,
  VisualTextArea,
} from "ui";
import { SessionDurationOptions } from "schemas";
import { useWatch } from "react-hook-form";
import { Conferencing } from "./Conferencing";
import { StepProps } from "./steps";
import { checkEntityLink, uploadImage, useCurrentBusiness } from "../../store";

const { REACT_APP_PUBLIC_DOMAIN } = import.meta.env;

export function BasicDetails({ id }: StepProps) {
  const business = useCurrentBusiness();
  const isPublic = useWatch({ name: "public" });
  const isVirtual = useWatch({ name: "isVirtual" });
  const base = `${REACT_APP_PUBLIC_DOMAIN}/${business!.link}/`;

  const checkAppointmentLink = async (link: string, abortController: AbortController) => {
    const available = await checkEntityLink({ type: "appointment", link, id }, abortController);
    return available ? "" : `${link} is already taken`;
  };

  const publicLabel = isPublic ? "Visible on your LiteSite" : "Unlisted";

  return (
    <>
      <CheckboxWithLabel name="public">{publicLabel}</CheckboxWithLabel>
      <Divider />
      <TextInput name="eventName" label="Appointment name" placeholder="Name the appointment" />
      <Select name="sessionDuration" label="Duration" options={SessionDurationOptions} />
      <LinkInput name="link" label="Appointment link" base={base} check={checkAppointmentLink} slugFrom="eventName" />
      <VisualTextArea
        type="form"
        name="description"
        label="Appointment description"
        maxCharacterCount={200}
        placeholder="Describe the appointment"
      />
      <ImageInput urlValue cropAspect="Wide" name="image" uploadImage={uploadImage} />
      <Divider />
      <Radio<boolean> label="Appointment location" name="isVirtual" options={LocationOptions} />
      {isVirtual ? <Conferencing /> : <TextInput name="location" label="Location details" />}
    </>
  );
}
