import { TextBlockPayload, TextBlockSchema } from "schemas";
import { FormProvider, FormTag, VisualTextArea } from "ui";
import { Menu } from "./Menu";

interface Props {
  block: TextBlockPayload;
  onSubmit: (payload: TextBlockPayload) => Promise<void>;
  onCancel: () => void;
  onDelete?: () => Promise<void>;
}
export function TextEditor({ block, onDelete, onSubmit, onCancel }: Props) {
  return (
    <FormProvider<TextBlockPayload> schema={TextBlockSchema} defaultValues={block}>
      <FormTag<TextBlockPayload> onSubmit={onSubmit}>
        <VisualTextArea<TextBlockPayload>
          onSubmit={onSubmit}
          autoFocus
          type="inline"
          name="text"
          placeholder="Add a text block"
        />
        <Menu<TextBlockPayload> onDelete={onDelete} onSubmit={onSubmit} onCancel={onCancel} />
      </FormTag>
    </FormProvider>
  );
}
