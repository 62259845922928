import { toast } from "react-toastify";
import { Icon, ShareLink, bgStyles, flexStyles, iconStyles, paddingStyles } from "ui";

import classNames from "classnames";
import { useCurrentBusiness } from "../../store";

const { REACT_APP_PUBLIC_DOMAIN } = import.meta.env;

export function Header() {
  const business = useCurrentBusiness();

  if (!business) {
    return null;
  }

  return (
    <div
      className={classNames(
        iconStyles.primary,
        flexStyles.horizApart,
        flexStyles.gap100,
        paddingStyles.p125,
        paddingStyles.pb100,
        paddingStyles.pt325Tablet,
        paddingStyles.pb200Tablet,
        bgStyles.neutral0,
      )}
    >
      <Icon iconType="Logo" className={classNames(iconStyles.width950Tablet)} />
      <ShareLink relativeUrl={`${business.relativeUrl}/subscribe`} host={REACT_APP_PUBLIC_DOMAIN} onCopy={toast} />
    </div>
  );
}
