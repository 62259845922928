import { Asserts, number, object, string } from "yup";
import { LinkValidation } from "./Link";
import { BusinessPlan, FeatureFlagsRecord } from "../enums";
import { BookingIncrementValues, SoonestBookingHoursValues } from "../consts";
import { EmailValidation } from "./shared";

export const BusinessNameString = string().required("Business name is required");

export const BusinessSchema = object({
  businessName: BusinessNameString,
  link: LinkValidation,
  description: string().max(300, "Description can not be longer than 300 characters"),
  logo: string().url().nullable(),
  defaultIncrement: number().oneOf(BookingIncrementValues),
  timeZone: string(),
  address: string(),
  soonestBookingHours: number().oneOf(SoonestBookingHoursValues),
  emailSettings: object({ fromEmail: string(), fromName: string(), replyToEmail: EmailValidation }),
});

export type BusinessPayload = Asserts<typeof BusinessSchema>;
export type SharedBusiness = BusinessPayload & {
  _id: string;
  timeZone: string;
  relativeUrl: string;
  resolvedLink: string;
  paidFeatures: {
    multipleCalendars: boolean;
    noBranding: boolean;
    smsReminders: boolean;
  };
  customerPayments: {
    stripeAccountId?: string;
  };
};
export type PersistedBusiness = SharedBusiness & {
  url: string;
  plan: BusinessPlan;
  planName: string;
  customerPayments: {
    enabled: boolean;
    currency?: string;
  };
  featureFlags: FeatureFlagsRecord;
  hasZoom: boolean;
  hasAppointments: boolean;
  emailSettings: {
    fromEmail?: string;
    fromName?: string;
    replyToEmail?: string;
    resolvedFromEmail: string;
    resolvedFromName: string;
    resolvedReplyToEmail: string;
  };
};

export const EmptyBusiness: BusinessPayload = {
  businessName: "",
  description: "",
  logo: undefined,
  timeZone: undefined,
  defaultIncrement: 15,
  emailSettings: {},
};
