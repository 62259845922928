import { VideoBlockSchema, VideoBlockPayload } from "schemas";
import { FormProvider, FormTag, InlineTextInput, stopPropagation } from "ui";
import { Menu } from "./Menu";

interface Props {
  block: VideoBlockPayload;
  onSubmit: (payload: VideoBlockPayload) => Promise<void>;
  onCancel: () => void;
  onDelete?: () => Promise<void>;
}
export function VideoLinkEditor({ block, onDelete, onSubmit, onCancel }: Props) {
  return (
    <FormProvider<VideoBlockPayload> schema={VideoBlockSchema} defaultValues={block}>
      <FormTag<VideoBlockPayload> onSubmit={onSubmit} onClick={stopPropagation}>
        <InlineTextInput
          size="Text"
          label="Youtube video link"
          name="video"
          placeholder="Video URL"
          onSubmit={onSubmit}
        />
        <Menu<VideoBlockPayload> onDelete={onDelete} onSubmit={onSubmit} onCancel={onCancel} />
      </FormTag>
    </FormProvider>
  );
}
