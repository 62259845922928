import { apiRequest } from "../apiRequest";
import { idsParams } from "../helpers";
import { unstoreContact } from "./unstoreContact";

export async function batchDeleteContacts(ids: number[]) {
  try {
    if (ids.length > 0) {
      await apiRequest<{ deletedIds: number[] }>({
        url: `contacts?${idsParams(ids).toString()}`,
        method: "DELETE",
      });
      ids.map(unstoreContact);
    }
  } catch {
    // not found
  }
}
