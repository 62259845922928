import { PropsWithClassNameAndChildren } from "ui";

import classNames from "classnames";
import { Link } from "./Link";
import styles from "./Button.module.css";

export type ButtonType = "Primary" | "Secondary" | "Tertiary";

interface Props extends PropsWithClassNameAndChildren {
  buttonType?: ButtonType;
  to?: string;
  href?: string;
  target?: "_blank";
  disabled?: boolean;
  onClick?: () => void;
  htmlButtonType?: "button" | "submit";
}

export function Button({
  children,
  href,
  to,
  onClick,
  className,
  target,
  buttonType = "Primary",
  htmlButtonType = "button",
  disabled = false,
}: Props) {
  return (
    <Link
      target={target}
      className={classNames(
        styles.button,
        {
          [styles.primary]: buttonType === "Primary",
          [styles.secondary]: buttonType === "Secondary",
          [styles.tertiary]: buttonType === "Tertiary",
        },
        className,
      )}
      to={to}
      href={href}
      onClick={onClick}
      htmlButtonType={htmlButtonType}
      disabled={disabled}
    >
      {children}
    </Link>
  );
}
