import { useSearchParams } from "react-router-dom";

interface Hook {
  searchQuery: string;
  setSearchQuery: (searchQuery: string | undefined) => void;
  searchQueryExists: boolean;
}

const queryParam = "query";
export function useSearchQuery(): Hook {
  const [query, setQuery] = useSearchParams();

  function setSearchQuery(newQuery: string | undefined) {
    query.set(queryParam, newQuery || "");
    setQuery(query, { replace: true });
  }

  const searchQueryExists = !!query.get(queryParam);

  return {
    searchQuery: query.get(queryParam) || "",
    setSearchQuery,
    searchQueryExists,
  };
}
