import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";

export async function deleteForm(id: string) {
  await apiRequest({ url: `forms/${id}`, method: "DELETE" });
  useLiteSiteStore.setState((state) => {
    const formsList = state.formsList.filter((form) => form._id !== id);
    state.forms.delete(id);
    return { formsList, forms: state.forms };
  });
}
