import { FormProvider, Loader, ResponsiveWrapper, TextAccent, flexStyles, paddingStyles } from "ui";
import { useContactGroup, useContacts } from "../../store";
import classNames from "classnames";
import { ContactsList } from "../contacts/ContactsList";
import { filterContacts } from "../../helpers/filterContacts";
import { AllCheckbox } from "./AllCheckbox";
import { SelectContactsSchema } from "schemas";
import { SelectBottomBar } from "./SelectBottomBar";
import { Outlet, useNavigate } from "react-router-dom";
import { Overlay } from "../ui";

export function Select() {
  const contacts = useContacts();
  const navigate = useNavigate();
  const contactGroup = useContactGroup();
  const onBack = () => navigate("..", { relative: "path" });
  if (!contactGroup) {
    return <Loader />;
  }

  const contactsToRender = contactGroup ? filterContacts(contacts, contactGroup) : contacts;

  return (
    <FormProvider defaultValues={{ contacts: [] }} schema={SelectContactsSchema}>
      <Overlay full header={contactGroup.name} backTo={{ to: "..", label: `${contactGroup.name} List` }}>
        <ResponsiveWrapper className={classNames(flexStyles.vert050, flexStyles.grow, paddingStyles.p125)}>
          <div className={classNames(flexStyles.horiz125, flexStyles.alignCenter, paddingStyles.py075)}>
            <AllCheckbox name="contacts" collection={contactsToRender} />
            <TextAccent className={flexStyles.grow}>Contacts ({contactsToRender.length})</TextAccent>
          </div>
          <ContactsList newContactTo="contacts/new" hideInitials name="contacts" contacts={contactsToRender} />
        </ResponsiveWrapper>
        <SelectBottomBar name="contacts" onDone={onBack} />
        <Outlet />
      </Overlay>
    </FormProvider>
  );
}
