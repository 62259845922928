import { ImageBlockPayload, ImageBlockSchema } from "schemas";
import { FormProvider, FormTag, Help, ImageInput, InlineTextInput, iconStyles, stopPropagation } from "ui";
import { Menu } from "./Menu";
import { uploadImage } from "../../../store";

interface Props {
  block: ImageBlockPayload;
  onSubmit: (payload: ImageBlockPayload) => Promise<void>;
  onCancel: () => void;
  onDelete?: () => Promise<void>;
}

export function ImageEditor({ block, onDelete, onSubmit, onCancel }: Props) {
  const altHelp = (
    <Help className={iconStyles.size100}>Alt text is used by screen readers and when the image fails to load.</Help>
  );
  return (
    <FormProvider<ImageBlockPayload> schema={ImageBlockSchema} defaultValues={block}>
      <FormTag<ImageBlockPayload> onSubmit={onSubmit} onClick={stopPropagation}>
        <ImageInput urlValue uploadImage={uploadImage} name="image" />
        <InlineTextInput<ImageBlockPayload>
          label="Image link URL"
          size="Text"
          name="url"
          placeholder="URL"
          onSubmit={onSubmit}
        />
        <InlineTextInput<ImageBlockPayload>
          label="Image alt text"
          size="Text"
          name="alt"
          placeholder="Image's alt text"
          onSubmit={onSubmit}
          extra={altHelp}
        />
        <Menu<ImageBlockPayload> onDelete={onDelete} onSubmit={onSubmit} onCancel={onCancel} />
      </FormTag>
    </FormProvider>
  );
}
