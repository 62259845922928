import { PersistedContact } from "schemas";
import { apiRequest } from "../apiRequest";
import { idsParams } from "../helpers";
import { setContact } from "./setContact";

export async function batchBlockContacts(ids: number[]) {
  const contacts = await apiRequest<PersistedContact[]>({
    url: `contacts/block?${idsParams(ids).toString()}`,
    method: "PATCH",
  });

  contacts.map(setContact);
}
