import { PersistedContact } from "schemas";
import { useLiteSiteStore } from "../store";
import { apiRequest } from "../apiRequest";

export async function searchContacts(query: string) {
  const contacts = await apiRequest<PersistedContact[]>({ url: `contacts/search/${query}` });

  useLiteSiteStore.setState(({ contactsSearchResults }) => ({
    contactsSearchResults: contactsSearchResults.set(query, contacts),
  }));
}
