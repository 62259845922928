import { ContactPayload, PersistedContact } from "schemas";
import { setContact } from "./setContact";
import { apiRequest } from "../apiRequest";

export async function updateContact(id: number, payload: ContactPayload) {
  const contact = await apiRequest<PersistedContact>({
    url: `contacts/${id}`,
    method: "PATCH",
    body: JSON.stringify(payload),
  });

  setContact(contact);
}
