import classNames from "classnames";
import { ReactNode } from "react";
import { borderStyles, flexStyles, heightStyles, iconStyles, paddingStyles, positionStyles, widthStyles } from "ui";

interface Props {
  title: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  noBorder?: boolean;
}

export function Header({ left, title, right, noBorder }: Props) {
  return (
    <div
      className={classNames(
        iconStyles.secondary300,
        flexStyles.horiz050,
        flexStyles.alignCenter,
        positionStyles.relative,
        heightStyles.min375,
        paddingStyles.py100,
        paddingStyles.ps125,
        widthStyles.full,
        { [borderStyles.bottomNeutral100]: !noBorder, [paddingStyles.pe300]: !!right, [paddingStyles.pe125]: !right },
      )}
    >
      {left}
      {title}
      {right && (
        <div
          className={classNames(
            iconStyles.primary,
            positionStyles.absolute,
            positionStyles.top125,
            positionStyles.right125,
          )}
        >
          {right}
        </div>
      )}
    </div>
  );
}
