import { BlockKind } from "schemas";
import { useDestinationIndex } from "./useDestinationIndex";
import { useBlockKindParam } from "./useBlockKindParam";

type Hook =
  | {
      destinationIndex: number;
      kind: BlockKind;
    }
  | undefined;
export function useNewBlock(): Hook {
  const destinationIndex = useDestinationIndex();
  const kind = useBlockKindParam();

  if (destinationIndex !== undefined && destinationIndex !== null && kind) {
    return { destinationIndex: +destinationIndex, kind };
  }
  return undefined;
}
