import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  IconType,
  SmallTextMain,
  SubheaderMain,
  SmallTextAccent,
  RawIcon,
  flexStyles,
  bgStyles,
  paddingStyles,
  borderStyles,
  marginStyles,
  widthStyles,
} from "ui";
import classNames from "classnames";
import { Button } from "../ui";
import { SuccessCard } from "../ui/SuccessCard";

interface FeatureProps extends PropsWithChildren {
  header: string;
  description: string;
  iconType: IconType;
}
function Feature({ header, description, iconType, children }: FeatureProps) {
  return (
    <div
      className={classNames(
        flexStyles.vert050,
        flexStyles.alignCenter,
        paddingStyles.p125,
        bgStyles.neutral0,
        borderStyles.neutral200,
      )}
    >
      <RawIcon className={marginStyles.mb100} iconType={iconType} />
      <SmallTextAccent>{header}</SmallTextAccent>
      <SmallTextMain>{description}</SmallTextMain>
      {children}
    </div>
  );
}

export function Success() {
  const navigate = useNavigate();
  return (
    <Modal header="LiteSite Business" onClose={() => navigate("/more")}>
      <div className={classNames(flexStyles.vert125, paddingStyles.p125)}>
        <SuccessCard
          header="Congratulations! You've Upgraded to Premium."
          subheader="You are successfully paid for our premium plan."
          successTo="/"
        />
        <SubheaderMain>Enjoy all the premium features.</SubheaderMain>
        <Feature
          iconType="MultipleCalendars"
          header="Multiple Calendars"
          description="You can now create and manage multiple calendars with ease."
        >
          <Button
            className={classNames(marginStyles.mt100, widthStyles.full)}
            buttonType="Secondary"
            to="/settings/calendars"
          >
            Add calendar
          </Button>
        </Feature>
        <Feature
          iconType="RemoveLiteSite"
          header="Remove LiteSite Branding"
          description="Say goodbye to our branding on your site."
        />
        <Feature
          iconType="SMSMarketing"
          header="SMS Marketing"
          description="Engage with your customers on a more personal level with our SMS marketing feature."
        />
      </div>
    </Modal>
  );
}
