import { flexStyles, GrayGrow, Loader, paddingStyles, ResponsiveWrapper } from "ui";
import { Button } from "../ui";
import classNames from "classnames";
import { useContactGroups, useContacts } from "../../store";
import { ContactGroupCard } from "./ContactGroupCard";
import { Outlet } from "react-router-dom";
import { AddYourFirstContact } from "./AddYourFirstContact";

export function List() {
  const { loading, contactGroups } = useContactGroups();
  const contacts = useContacts();

  if (loading) {
    return <Loader full />;
  }

  if (contacts.length === 0) {
    return <AddYourFirstContact />;
  }

  return (
    <GrayGrow>
      <ResponsiveWrapper className={classNames(flexStyles.vert125, paddingStyles.p125)}>
        <Button to="/contactGroups/new">+ CREATE NEW</Button>
        <div className={classNames(flexStyles.vert050)}>
          {contactGroups.map((contactGroup) => (
            <ContactGroupCard tall key={`cgc-${contactGroup.id}`} contactGroup={contactGroup} />
          ))}
        </div>
        <Outlet />
      </ResponsiveWrapper>
    </GrayGrow>
  );
}
