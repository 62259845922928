import { SharedBusiness } from "schemas";
import { useState } from "react";
import classNames from "classnames";
import { BusinessDetailsCard, PopoverLinkItem, PropsWithOnClick, flexStyles } from "ui";
import { EditWrapper } from "../../blocks";

interface Props extends PropsWithOnClick {
  business: SharedBusiness;
  links?: PopoverLinkItem[];
}
export function EditBusinessAddress({ business, links, onClick }: Props) {
  const [open, setOpen] = useState(false);
  return (
    <EditWrapper
      open={open}
      onClick={() => setOpen(!open)}
      className={classNames(flexStyles.alignCenter, flexStyles.gap050)}
      links={links}
    >
      <BusinessDetailsCard business={business} onClick={onClick} />
    </EditWrapper>
  );
}
