import { HeaderMain, TextMain, flexStyles } from "ui";
import { Button, HomeWrapper } from "../ui";
import { Upcoming } from "../booking";
import { useCurrentBusiness } from "../../store";

export function Home() {
  const { hasAppointments } = useCurrentBusiness();
  if (hasAppointments) {
    return <Upcoming />;
  }

  return (
    <HomeWrapper>
      <HeaderMain>Get started with LiteSite</HeaderMain>
      <div className={flexStyles.vert100}>
        <TextMain>LiteSite simplifies your customer outreach with easy email campaigns.</TextMain>
        <Button to="/campaigns">Create your first campaign</Button>
      </div>
      <div className={flexStyles.vert100}>
        <TextMain>Your LiteSite is made of blocks like images, scheduling and more.</TextMain>
        <Button to="/content">Add your first block</Button>
      </div>
      <div className={flexStyles.vert100}>
        <TextMain>
          LiteSite appointments are easy, self-service bookings sychronised with your Google Calendar.
        </TextMain>
        <Button to="/content">Create an appointment</Button>
      </div>
    </HomeWrapper>
  );
}
