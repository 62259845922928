import { MouseEvent, PropsWithChildren } from "react";
import { OnClickHandler } from "../types";
import styles from "./Button.module.css";
import classNames from "classnames";
import { borderStyles, flexStyles, paddingStyles, pointerStyles, textStyles } from "../styles";

interface Props extends PropsWithChildren {
  onClick: OnClickHandler | ((e: MouseEvent<HTMLButtonElement>) => Promise<void>);
  disabled?: boolean;
}

export function Button({ children, onClick, disabled = false }: Props) {
  return (
    <button
      type="button"
      className={classNames(
        flexStyles.horiz,
        flexStyles.alignCenter,
        flexStyles.justifyCenter,
        pointerStyles.cursor,
        textStyles.uppercase,
        textStyles.decorationNone,
        textStyles.size100,
        textStyles.weight600,
        borderStyles.radius025,
        paddingStyles.py050,
        paddingStyles.px125,
        styles.btn,
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
